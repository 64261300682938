import React from 'react';
import { useSearchParams, useLocation, useNavigate } from "react-router-dom";
import { Grid, ButtonGroup, Button } from "@mui/material";
  
export default function CarSubmenu() {
    const navigate = useNavigate();
    const location = useLocation();
    const [queryParameters] = useSearchParams();

    const subPage = location.pathname.match(/\/.*\/([^/]+)/);

    return (
        <Grid container spacing={1} sx={{ my: 2 }}>
        <ButtonGroup variant="text" aria-label="outlined primary button group">
        <Button variant={subPage[1] == "view" ? "contained" : ""} onClick={() => {navigate('/cars/view?id=' + queryParameters.get('id'))}}>Podaci</Button>
        <Button variant={subPage[1] == "damages" ? "contained" : ""} onClick={() => {navigate('/cars/damages?id=' + queryParameters.get('id'))}}>Oštećenja</Button>
        <Button variant={subPage[1] == "insurances" ? "contained" : ""} onClick={() => {navigate('/cars/insurances?id=' + queryParameters.get('id'))}}>Osiguranja</Button>
        <Button variant={subPage[1] == "registrations" ? "contained" : ""} onClick={() => {navigate('/cars/registrations?id=' + queryParameters.get('id'))}}>Registracije</Button>
        <Button variant={subPage[1] == "services" ? "contained" : ""} onClick={() => {navigate('/cars/services?id=' + queryParameters.get('id'))}}>Servisi</Button>
        <Button variant={subPage[1] == "tires" ? "contained" : ""} onClick={() => {navigate('/cars/tires?id=' + queryParameters.get('id'))}}>Pneumatici</Button>
        </ButtonGroup>
    </Grid>
    )
}