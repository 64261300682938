import React, { useState, useEffect } from 'react';
import { useSearchParams, useNavigate, useLocation } from "react-router-dom";
import { Typography, Divider, Button, Grid, Container, TextField } from '@mui/material';
import axios from 'axios';
  
import AppContext from '../../components/AppContext';
import ErrorHandler from '../../components/ErrorHandler';
import Loading from '../../components/Loading';

import Config from '../../utils/Config';
import {TimestampToDateTime} from '../../utils/Helpers';

export default function View() {
  const navigate = useNavigate();
  const [queryParameters] = useSearchParams();
  const [data, setData] = useState(false);
  const [imageDashboardHeight, setImageDashboardHeight] = useState(100);
  const [imageIdCardHeight, setImageIdCardHeight] = useState(100);
  const [imageIdCardBackHeight, setImageIdCardBackHeight] = useState(100);
  const [imageLicenceHeight, setImageLicenceHeight] = useState(100);
  const [imageLicenceBackHeight, setImageLicenceBackHeight] = useState(100);
  const [imageCarLeftTopHeight, setImageCarLeftTopHeight] = useState(100);
  const [imageCarLeftBottomHeight, setImageCarLeftBottomHeight] = useState(100);
  const [imageCarRightTopHeight, setImageCarRightTopHeight] = useState(100);
  const [imageCarRightBottomHeight, setImageCarRightBottomHeight] = useState(100);
  const [imageCarBackLeftTopHeight, setImageCarBackLeftTopHeight] = useState(100);
  const [imageCarBackLeftBottomHeight, setImageCarBackLeftBottomHeight] = useState(100);
  const [imageCarBackRightTopHeight, setImageCarBackRightTopHeight] = useState(100);
  const [imageCarBackRightBottomHeight, setImageCarBackRightBottomHeight] = useState(100);
  
  const { appContext } = React.useContext(AppContext);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(Config.BASE_URL + '/admin/damages/get?id=' + queryParameters.get('id'), {
          headers: {
            Authorization: 'Bearer ' + appContext.userToken, 
            'Cache-Control': 'no-cache',
          },
        });

        setData(response.data);
      } catch (error) {
        console.error(error);
        ErrorHandler(error.response, {'userToken': appContext.userToken, 'signOut': appContext.signOut});
      }
    };

    fetchData();
  }, []);
  
  if (data === false) {
    return (<Loading />);
  }

  if (!data) {
    return (
      <Container maxWidth="false" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 300 }}>
        <Typography variant="h4">Nema podataka</Typography>
      </Container>
    )
  }
  
  return (
    <>
    <Container maxWidth="false">
      <Grid container spacing={1} sx={{ my: 2 }}>
        <Typography variant="h4">{data.title}</Typography>
      </Grid>
      <Divider light />
      <Grid container spacing={1} sx={{ my: 1 }}>
        <Grid item xs={2} textAlign="left" sx={{ fontWeight: 'bold' }}>Vozilo:</Grid>
        <Grid item xs={3} textAlign="left">{data.car.garageNumber} - {data.car.registrationPlate} - {data.car.brand.name} {data.car.model}</Grid>
      </Grid>
      <Grid container spacing={1} sx={{ my: 1 }} className="FormRowOdd" >
        <Grid item xs={2} textAlign="left" sx={{ fontWeight: 'bold' }}>Datum i vreme:</Grid>
        <Grid item xs={3} textAlign="left">{TimestampToDateTime(data.date)}</Grid>
      </Grid>
      <Grid container spacing={1} sx={{ my: 1 }}>
        <Grid item xs={2} textAlign="left" sx={{ fontWeight: 'bold' }}>Opis oštećenja:</Grid>
        <Grid item xs={3} textAlign="left">{data.damageDescription}</Grid>
      </Grid>
      <Grid container spacing={1} sx={{ my: 1 }} className="FormRowOdd">
        <Grid item xs={2} textAlign="left" sx={{ fontWeight: 'bold' }}>Opis događaja:</Grid>
        <Grid item xs={3} textAlign="left">{data.eventDescription}</Grid>
      </Grid>
      <Grid container spacing={1} sx={{ my: 1 }}>
        <Grid item xs={2} textAlign="left" sx={{ fontWeight: 'bold' }}>Prijavljeno MUP-u:</Grid>
        <Grid item xs={3} textAlign="left">{data.policeReported ? "Da" : "Ne"}</Grid>
      </Grid>
      <Grid container spacing={1} sx={{ my: 1 }} className="FormRowOdd">
        <Grid item xs={2} textAlign="left" sx={{ fontWeight: 'bold' }}>Evropski izveštaj:</Grid>
        <Grid item xs={3} textAlign="left">{data.europeanReport ? "Da" : "Ne"}</Grid>
      </Grid>
      <Grid container spacing={1} sx={{ my: 1 }}>
        <Grid item xs={2} textAlign="left" sx={{ fontWeight: 'bold' }}>Stanje vozila:</Grid>
        <Grid item xs={3} textAlign="left">{data.carCondition}</Grid>
      </Grid>
      <Grid container spacing={1} sx={{ my: 1 }} className="FormRowOdd">
        <Grid item xs={2} textAlign="left" sx={{ fontWeight: 'bold' }}>Lokacija:</Grid>
        <Grid item xs={3} textAlign="left">{data.latitude}, {data.longitude}</Grid>
      </Grid>
      <Grid container spacing={1} sx={{ my: 1 }}>
        <Grid item xs={2} textAlign="left" sx={{ fontWeight: 'bold' }}>Adresa iz lične karte:</Grid>
        <Grid item xs={3} textAlign="left">{data.idAddress}</Grid>
      </Grid>
      <Grid container spacing={1} sx={{ my: 1 }} className="FormRowOdd">
        <Grid item xs={2} textAlign="left" sx={{ fontWeight: 'bold' }}>Vozačka dozvola:</Grid>
        <Grid item xs={3} textAlign="left">
          <img src={data.imageLicence} height={imageLicenceHeight} onClick={() => imageLicenceHeight == null ? setImageLicenceHeight(100) : setImageLicenceHeight(null) } />
          <img src={data.imageLicenceBack} height={imageLicenceBackHeight} onClick={() => imageLicenceBackHeight == null ? setImageLicenceBackHeight(100) : setImageLicenceBackHeight(null) } />
        </Grid>
      </Grid>
      <Grid container spacing={1} sx={{ my: 1 }}>
        <Grid item xs={2} textAlign="left" sx={{ fontWeight: 'bold' }}>Lična karta:</Grid>
        <Grid item xs={3} textAlign="left">
          <img src={data.imageIdCard} height={imageIdCardHeight} onClick={() => imageIdCardHeight == null ? setImageIdCardHeight(100) : setImageIdCardHeight(null) } />
          <img src={data.imageIdCardBack} height={imageIdCardBackHeight} onClick={() => imageIdCardBackHeight == null ? setImageIdCardBackHeight(100) : setImageIdCardBackHeight(null) } />
        </Grid>
      </Grid>
      <Grid container spacing={1} sx={{ my: 1 }} className="FormRowOdd">
        <Grid item xs={2} textAlign="left" sx={{ fontWeight: 'bold' }}>Vozilo:</Grid>
        <Grid item xs={3} textAlign="left">
        <img src={data.imageCarLeftTop} height={imageCarLeftTopHeight} onClick={() => imageCarLeftTopHeight == null ? setImageCarLeftTopHeight(100) : setImageCarLeftTopHeight(null) } />
        <img src={data.imageCarLeftBottom} height={imageCarLeftBottomHeight} onClick={() => imageCarLeftBottomHeight == null ? setImageCarLeftBottomHeight(100) : setImageCarLeftBottomHeight(null) } />
        <img src={data.imageCarRightTop} height={imageCarRightTopHeight} onClick={() => imageCarRightTopHeight == null ? setImageCarRightTopHeight(100) : setImageCarRightTopHeight(null) } />
        <img src={data.imageCarRightBottom} height={imageCarRightBottomHeight} onClick={() => imageCarRightBottomHeight == null ? setImageCarRightBottomHeight(100) : setImageCarRightBottomHeight(null) } />
        <img src={data.imageCarBackLeftTop} height={imageCarBackLeftTopHeight} onClick={() => imageCarBackLeftTopHeight == null ? setImageCarBackLeftTopHeight(100) : setImageCarBackLeftTopHeight(null) } />
        <img src={data.imageCarBackLeftBottom} height={imageCarBackLeftBottomHeight} onClick={() => imageCarBackLeftBottomHeight == null ? setImageCarBackLeftBottomHeight(100) : setImageCarBackLeftBottomHeight(null) } />
        <img src={data.imageCarBackRightTop} height={imageCarBackRightTopHeight} onClick={() => imageCarBackRightTopHeight == null ? setImageCarBackRightTopHeight(100) : setImageCarBackRightTopHeight(null) } />
        <img src={data.imageCarBackRightBottom} height={imageCarBackRightBottomHeight} onClick={() => imageCarBackRightBottomHeight == null ? setImageCarBackRightBottomHeight(100) : setImageCarBackRightBottomHeight(null) } />
        </Grid>
      </Grid>
      <Grid container spacing={1} sx={{ my: 1 }}>
        <Grid item xs={2} textAlign="left" sx={{ fontWeight: 'bold'}}>Korisnik:</Grid>
        <Grid item xs={3} textAlign="left">{data.user?.phone} - {data.user?.firstName} {data.user?.lastName}</Grid>
      </Grid>
    </Container>
    <Container maxWidth="false" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 20, marginBottom: 20 }}>
      <Button variant="contained" color="success" sx={{mx: 1}} onClick={() => navigate('/damages/edit?id=' + queryParameters.get('id'))}>Izmeni</Button>
      <Button variant="contained" color="primary" sx={{mx: 1}} onClick={() => navigate('/damages/list')}>Nazad</Button>
    </Container>
    </>
  );
}