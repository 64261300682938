import React, { useState, useEffect } from 'react';
import { useSearchParams, useNavigate } from "react-router-dom";
import { Typography, Divider, Button, Grid, Container, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TablePagination, Paper, TextField } from '@mui/material';
import { FormContainer, FormErrorProvider, AutocompleteElement } from 'react-hook-form-mui'
import TablePaginationActions from '../../components/TablePaginationActions';
import axios from 'axios';
  
import AppContext from '../../components/AppContext';
import ErrorHandler from '../../components/ErrorHandler';
import Loading from '../../components/Loading';
import UserSubmenu from '../../components/UserSubmenu';

import Config from '../../utils/Config';
import {TimestampToDateTime} from '../../utils/Helpers';

export default function List() {
  const navigate = useNavigate();
  const [queryParameters] = useSearchParams();

  const [data, setData] = useState(false);
  const [rows, setRows] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [cars, setCars] = useState([]);

  const { appContext } = React.useContext(AppContext);

  //console.log(userToken)

  const fetchList = async () => {
    try {
      const response = await axios.get(Config.BASE_URL + '/admin/users/getAssignedVehiclesByUser', {
        headers: {
          Authorization: 'Bearer ' + appContext.userToken, 
          'Cache-Control': 'no-cache',
        },
      });

      setRows(response.data);
    } catch (error) {
      console.error(error);
      ErrorHandler(error.response, {'userToken': appContext.userToken, 'signOut': appContext.signOut});
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(Config.BASE_URL + '/admin/users/get?id=' + queryParameters.get('id'), {
          headers: {
            Authorization: 'Bearer ' + appContext.userToken, 
            'Cache-Control': 'no-cache',
          },
        });

        setData(response.data);
      } catch (error) {
        console.error(error);
        ErrorHandler(error.response, {'userToken': appContext.userToken, 'signOut': appContext.signOut});
      }
    };

    const fetchCars = async () => {
      try {
        const response = await axios.get(Config.BASE_URL + '/admin/cars/list', {
          headers: {
            Authorization: 'Bearer ' + appContext.userToken, 
            'Cache-Control': 'no-cache',
          },
        });
  
        for (const dataId in response.data) {
          response.data[dataId]["label"] = response.data[dataId]["garageNumber"] + " - " + response.data[dataId]["registrationPlate"] + " - " + response.data[dataId]["brandName"] + " " + response.data[dataId]["model"];
        }
  
        setCars(response.data);
      } catch (error) {
        console.error(error);
        ErrorHandler(error.response, {'userToken': appContext.userToken, 'signOut': appContext.signOut});
      }
    };  

    fetchData();
    fetchList();
    fetchCars();

  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleAssignVehicle = async (formData) => {
    await axios.get(Config.BASE_URL + '/admin/users/addAssignedVehiclesByUser?carId=' + formData.carId, {
      headers: {
        Authorization: 'Bearer ' + appContext.userToken, 
        'Cache-Control': 'no-cache',
      },
    });

    fetchList();
  };
  
  const removeVehicle = async (id) => {
    await axios.get(Config.BASE_URL + '/admin/users/removeAssignedVehiclesByUser?carId=' + id, {
      headers: {
        Authorization: 'Bearer ' + appContext.userToken, 
        'Cache-Control': 'no-cache',
      },
    });

    fetchList();
  };
  
  console.log(rows)
  
  if (rows === false) {
    return (<Loading />);
  }

  return (
      <Container maxWidth="false">
        <Grid container spacing={1} sx={{ my: 2 }}>
          <Typography variant="h4">{data.firstName} {data.lastName}</Typography>
        </Grid>
        <Divider light />
        <UserSubmenu user={data} />
        <FormErrorProvider
              onError={(error) => {
                if (error.type === 'required') {
                  return 'Popunite obavezno polje'
                }
                return error?.message
              }}
            >
        <FormContainer onSuccess={formData => handleAssignVehicle(formData)}>
        <Grid container spacing={1} sx={{ my: 2 }}>
          <Grid item xs={1} textAlign="center" sx={{mt: 1}}>
            Ukupno: {rows.length}
          </Grid>
          <Grid item xs={3} textAlign="center">
            <AutocompleteElement
                name="carId"
                matchId
                fullWidth
                required
                options={cars}
                textFieldProps={{
                  InputProps: { size: "small" },
                }}
                />
          </Grid>
          <Grid item xs={8}>
            <Button variant="contained" color="success" type="submit">Dodeli vozilo</Button>
          </Grid>
        </Grid>
        </FormContainer>
        </FormErrorProvider>
        <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
              labelRowsPerPage="Redova po stranici"
          />
          <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead color="secondary">
              <TableRow>
                  <TableCell>Garažni broj</TableCell>
                  <TableCell align="left">Registracija</TableCell>
                  <TableCell align="left">Zakupac</TableCell>
                  <TableCell align="left">Marka</TableCell>
                  <TableCell align="left">Model</TableCell>
                  <TableCell align="left">Godina</TableCell>
                  <TableCell align="left">Status</TableCell>
                  <TableCell align="left">Upisan</TableCell>
                  <TableCell align="left"></TableCell>
              </TableRow>
              </TableHead>
              <TableBody>
              {rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => (
                  <TableRow
                  key={row.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 }, 
                        '&:nth-of-type(even)': { backgroundColor: "#f6f6f6" },
                        '&:hover': { backgroundColor: "#fffbc7", cursor: 'pointer' } }}
                  onClick={() => navigate('/cars/view?id=' + row.id)}
                  >
                  <TableCell component="th" scope="row">{row.garageNumber}</TableCell>
                  <TableCell align="left">{row.registrationPlate}</TableCell>
                  <TableCell align="left">{row.clientName}</TableCell>
                  <TableCell align="left">{row.brandName}</TableCell>
                  <TableCell align="left">{row.model}</TableCell>
                  <TableCell align="left">{row.manufactureYear}</TableCell>
                  <TableCell align="left">{row.status}</TableCell>
                  <TableCell align="left">{TimestampToDateTime(row.created)}</TableCell>
                  <TableCell align="left"><Button variant="text" color="secondary" size="small" sx={{m:0, p:0}} onClick={(e) => { removeVehicle(row.id); e.stopPropagation(); }}>Izbaci</Button></TableCell>
                  </TableRow>
              ))}
              </TableBody>
          </Table>
          </TableContainer>
          <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
              labelRowsPerPage="Redova po stranici"
          />
      </Container>
  );
}
