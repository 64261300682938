import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { Typography, Divider, Button, Grid, Container, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TablePagination, Paper, TextField, Link } from '@mui/material';
import TablePaginationActions from '../../components/TablePaginationActions';
import axios from 'axios';
  
import AppContext from '../../components/AppContext';
import ErrorHandler from '../../components/ErrorHandler';
import Loading from '../../components/Loading';

import Config from '../../utils/Config';
import {TimestampToDateTime, TimestampToMillisNumber} from '../../utils/Helpers';

export default function List() {
  const navigate = useNavigate();

  const [data, setData] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [filter, setFilter] = useState({clientName: "", userName: "", startDate: "", endDate: ""});

  const [filterField1, setFilterField1] = useState('');
  const [filterField2, setFilterField2] = useState('');
  const [filterField3, setFilterField3] = useState('');
  const [filterField4, setFilterField4] = useState('');

  const { appContext } = React.useContext(AppContext);

  //console.log(userToken)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(Config.BASE_URL + '/admin/damages/list', {
          headers: {
            Authorization: 'Bearer ' + appContext.userToken, 
            'Cache-Control': 'no-cache',
          },
        });

        setData(response.data);
      } catch (error) {
        console.error(error);
        ErrorHandler(response, {'userToken': appContext.userToken, 'signOut': appContext.signOut});
      }
    };

    fetchData();
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleSetFilterButtonClick = () => {
    setFilter({clientName: filterField1, userName: filterField2, startDate: filterField3, endDate: filterField4});
    setPage(0);
  };

  const handleClearFilterButtonClick = () => {
    setFilterField1('');
    setFilterField2('');
    setFilterField3('');
    setFilterField4('');

    setFilter({clientName: "", userName: "", startDate: "", endDate: ""});
  };

  console.log(data)
  
  if (data === false) {
    return (<Loading />);
  }

  function filterDateFrom(row) {
    if (filter.startDate) {
      return TimestampToMillisNumber(row.date) >= TimestampToMillisNumber(filter.startDate);
    } else {
      return true;
    }
  }

  function filterDateTo(row) {
    if (filter.endDate) {
      return TimestampToMillisNumber(row.date) <= TimestampToMillisNumber(filter.endDate);
    } else {
      return true;
    }
  }

  const rows = data.filter((el) => el.clientName.toLowerCase().includes(filter.clientName.toLowerCase()))
                   .filter((el) => (el.userName || '').toLowerCase().includes(filter.userName.toLowerCase()))
                   .filter(filterDateFrom)
                   .filter(filterDateTo);
  
  return (
      <Container maxWidth="false">
        <Grid container spacing={1} sx={{ my: 2 }}>
          <Grid item xs={10}>
            <Typography variant="h4">Oštećenja</Typography>
          </Grid>
          <Grid item xs={2} textAlign="center">
            <Button variant="contained" color="success" onClick={() => navigate('/damages/add')}>Dodaj oštećenje</Button>
          </Grid>
        </Grid>
        <Divider light />
        <Grid container spacing={1} sx={{ my: 2 }}>
          <Grid item xs={1} textAlign="center" sx={{mt: 1}}>
            Ukupno: {rows.length}
          </Grid>
          <Grid item xs={2} textAlign="center">
            <TextField label="Zakupac" value={filterField1} onChange={(e) => setFilterField1(e.target.value)} variant="outlined" size="small" InputLabelProps={{ shrink: true }} />
          </Grid>
          <Grid item xs={2} textAlign="center">
            <TextField label="Korisnik" value={filterField2} onChange={(e) => setFilterField2(e.target.value)} variant="outlined" size="small" InputLabelProps={{ shrink: true }} />
          </Grid>
          <Grid item xs={2} textAlign="center">
            <TextField label="OD" value={filterField3} type="date" onChange={(e) => setFilterField3(e.target.value)} variant="outlined" size="small" InputLabelProps={{ shrink: true }} />
          </Grid>
          <Grid item xs={2} textAlign="center">
            <TextField label="DO" value={filterField4} type="date" onChange={(e) => setFilterField4(e.target.value)} variant="outlined" size="small" InputLabelProps={{ shrink: true }} />
          </Grid>
          <Grid item xs={3}>
            <Button variant="contained" color="primary" sx={{ mx: 1 }} onClick={handleSetFilterButtonClick}>Primeni</Button>
            <Button variant="contained" color="error" sx={{ mx: 1 }} onClick={handleClearFilterButtonClick}>Reset</Button>
          </Grid>
        </Grid>
        <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
              labelRowsPerPage="Redova po stranici"
          />
          <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead color="secondary">
              <TableRow>
                  <TableCell>Naziv</TableCell>
                  <TableCell align="left">Istorija po vozilu</TableCell>
                  <TableCell align="left">Zakupac</TableCell>
                  <TableCell align="left">Korisnik</TableCell>
                  <TableCell align="left">Datum</TableCell>
                  <TableCell align="left"></TableCell>
              </TableRow>
              </TableHead>
              <TableBody>
              {rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => (
                  <TableRow
                  key={row.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 }, 
                        '&:nth-of-type(even)': { backgroundColor: "#f6f6f6" },
                        '&:hover': { backgroundColor: "#fffbc7", cursor: 'pointer' } }}
                  onClick={() => navigate('/damages/view?id=' + row.id)}
                  >
                  <TableCell component="th" scope="row">{row.title}</TableCell>
                  <TableCell align="left"><Link onClick={(e) => { navigate('/cars/damages?id=' + row.carId); e.stopPropagation(); }}>{row.carGarageNumber} - {row.carRegistrationPlate}</Link></TableCell>
                  <TableCell align="left">{row.clientName}</TableCell>
                  <TableCell align="left">{row.userName}</TableCell>
                  <TableCell align="left">{TimestampToDateTime(row.date)}</TableCell>
                  <TableCell align="left"><Button variant="text" color="secondary" size="small" sx={{m:0, p:0}} onClick={(e) => { navigate('/damages/edit?id=' + row.id); e.stopPropagation(); }}>Izmeni</Button></TableCell>
                  </TableRow>
              ))}
              </TableBody>
          </Table>
          </TableContainer>
          <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
              labelRowsPerPage="Redova po stranici"
          />
      </Container>
  );
}
