import React from 'react';

import Config from '../utils/Config';
import Messages from '../utils/Messages';

export default function ErrorHandler(response, options) {

    console.log("ErrorHandler")
    console.log(response)

    const data = response.data;

    alert(Messages[data.error] ? Messages[data.error] : "[" + data.error + "]");

    if (data.status == 401 && options.signOut) {
        options.signOut();
    } else if (options.onPressOK) {
        options.onPressOK();
    }    
}