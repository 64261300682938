import React, { useState, useEffect } from 'react';
import { useSearchParams, useNavigate, useLocation } from "react-router-dom";
import { Typography, Divider, Button, Grid, Container, TextField } from '@mui/material';
import {FormErrorProvider, FormContainer, TextFieldElement, SelectElement, AutocompleteElement} from 'react-hook-form-mui'
import axios from 'axios';
  
import AppContext from '../../components/AppContext';
import ErrorHandler from '../../components/ErrorHandler';
import Loading from '../../components/Loading';

import Config from '../../utils/Config';

export default function Edit() {
  const navigate = useNavigate();
  const [queryParameters] = useSearchParams();
  const [data, setData] = useState(false);
  const [cars, setCars] = useState([]);
  const [insuranceCompanies, setInsuranceCompanies] = useState([]);

  const { appContext } = React.useContext(AppContext);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(Config.BASE_URL + '/admin/insurances/get?id=' + queryParameters.get('id'), {
          headers: {
            Authorization: 'Bearer ' + appContext.userToken, 
            'Cache-Control': 'no-cache',
          },
        });

        // prepare for form inputs
        response.data.carId = response.data.car.carId;
        response.data.insuranceCompanyId = response.data.insuranceCompany?.insuranceCompanyId;
        
        // remove not needed data
        delete response.data.car;
        delete response.data.insuranceCompany;

        setData(response.data);
      } catch (error) {
        console.error(error);
        ErrorHandler(error, {'userToken': appContext.userToken, 'signOut': appContext.signOut});
      }
    };

    const fetchCars = async () => {
      try {
        const response = await axios.get(Config.BASE_URL + '/admin/cars/list', {
          headers: {
            Authorization: 'Bearer ' + appContext.userToken, 
            'Cache-Control': 'no-cache',
          },
        });

        for (const dataId in response.data) {
          response.data[dataId]["label"] = response.data[dataId]["garageNumber"] + " - " + response.data[dataId]["registrationPlate"];
        }

        setCars(response.data);
      } catch (error) {
        console.error(error);
        ErrorHandler(error.response, {'userToken': appContext.userToken, 'signOut': appContext.signOut});
      }
    };

    const fetchInsurances = async () => {
      try {
        const response = await axios.get(Config.BASE_URL + '/admin/insurances/companies/list', {
          headers: {
            Authorization: 'Bearer ' + appContext.userToken, 
            'Cache-Control': 'no-cache',
          },
        });

        setInsuranceCompanies(response.data);
      } catch (error) {
        console.error(error);
        ErrorHandler(error.response, {'userToken': appContext.userToken, 'signOut': appContext.signOut});
      }
    };

    fetchData();
    fetchCars();
    fetchInsurances();
  }, []);

  const handleFormSubmit = async (formData) => {
    console.log("handleFormSubmit")
    console.log(formData)

    try {
      const response = await axios.post(Config.BASE_URL + '/admin/insurances/edit', formData, {
        headers: {
          Authorization: 'Bearer ' + appContext.userToken, 
        }
      });

      //console.log('Response:', response);

      navigate('/insurances/list');
    } catch (error) {
      console.error('Error:', error);
      ErrorHandler(error.response, {'userToken': appContext.userToken, 'signOut': appContext.signOut});
    }
  };

  console.log(queryParameters.get('id'))

  console.log(data.length)
  console.log(data)

  if (data === false) {
    return (<Loading />);
  }

  if (!data) {
    return (
      <Container maxWidth="false" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 300 }}>
        <Typography variant="h4">Nema podataka</Typography>
      </Container>
    )
  }

  return (
    <>
    <Container maxWidth="false">
      <Grid container spacing={1} sx={{ my: 2 }}>
        <Typography variant="h4">Izmena osiguranja Kasko polisa - {data.policyNumber}</Typography>
      </Grid>
      <Divider light />
      <FormErrorProvider
        onError={(error) => {
          if (error.type === 'required') {
            return 'Popunite obavezno polje'
          } else if (error.type === 'pattern') {
            return 'Unesite ispravnu e-mail adresu'
          }
          return error?.message
        }}
      >
        <FormContainer     
          defaultValues={data}
          onSuccess={formData => handleFormSubmit(formData)}>
          <Grid container spacing={1} sx={{ my: 1 }}>
            <Grid item xs={3} textAlign="left" sx={{ fontWeight: 'bold' }}>Vozilo: <Typography variant="h6" display="inline-block" color="red">*</Typography></Grid>
            <Grid item xs={3} textAlign="left">
              <AutocompleteElement
                name="carId"
                matchId
                fullWidth
                required
                options={cars}
                textFieldProps={{
                  InputProps: { size: "small" },
                }}
                />
            </Grid>
          </Grid>
          <Grid container spacing={1} sx={{ my: 1 }}>
            <Grid item xs={3} textAlign="left" sx={{ fontWeight: 'bold' }}>Osiguravajuća kuća: <Typography variant="h6" display="inline-block" color="red">*</Typography></Grid>
            <Grid item xs={3} textAlign="left">
              <SelectElement
                name="insuranceCompanyId"
                fullWidth
                required
                size="small"
                labelKey="name"
                valueKey="insuranceCompanyId"
                options={insuranceCompanies}
                />
            </Grid>
          </Grid>
          <Grid container spacing={1} sx={{ my: 1 }}>
            <Grid item xs={3} textAlign="left" sx={{ fontWeight: 'bold' }}>Broj polise:</Grid>
            <Grid item xs={3} textAlign="left"><TextFieldElement name="policyNumber" size="small" fullWidth /></Grid>
          </Grid>
          <Grid container spacing={1} sx={{ my: 1 }}>
            <Grid item xs={3} textAlign="left" sx={{ fontWeight: 'bold' }}>Period ugovora od:</Grid>
            <Grid item xs={3} textAlign="left"><TextFieldElement name="contractStartDate" size="small" type="date" fullWidth /></Grid>
          </Grid>
          <Grid container spacing={1} sx={{ my: 1 }}>
            <Grid item xs={3} textAlign="left" sx={{ fontWeight: 'bold' }}>Period ugovora do:</Grid>
            <Grid item xs={3} textAlign="left"><TextFieldElement name="contractEndDate" size="small" type="date" fullWidth /></Grid>
          </Grid>
          <Grid container spacing={1} sx={{ my: 1 }}>
            <Grid item xs={3} textAlign="left" sx={{ fontWeight: 'bold' }}>Obračunski period od:</Grid>
            <Grid item xs={3} textAlign="left"><TextFieldElement name="accountingStartDate" size="small" type="date" fullWidth /></Grid>
          </Grid>
          <Grid container spacing={1} sx={{ my: 1 }}>
            <Grid item xs={3} textAlign="left" sx={{ fontWeight: 'bold' }}>Obračunski period do:</Grid>
            <Grid item xs={3} textAlign="left"><TextFieldElement name="accountingEndDate" size="small" type="date" fullWidth /></Grid>
          </Grid>
          <Grid container spacing={1} sx={{ my: 1 }}>
            <Grid item xs={3} textAlign="left" sx={{ fontWeight: 'bold' }}>Iznos polise - godišnja premija:</Grid>
            <Grid item xs={3} textAlign="left"><TextFieldElement name="policyValue" size="small" fullWidth /></Grid>
          </Grid>
          <Grid container spacing={1} sx={{ my: 1 }}>
            <Grid item xs={3} textAlign="left" sx={{ fontWeight: 'bold' }}>Broj šteta po polisi:</Grid>
            <Grid item xs={3} textAlign="left"><TextFieldElement name="damageAmount" size="small" fullWidth /></Grid>
          </Grid>
          <Grid container spacing={1} sx={{ my: 1 }}>
            <Grid item xs={3} textAlign="left" sx={{ fontWeight: 'bold' }}>Učešće u šteti u procentima:</Grid>
            <Grid item xs={3} textAlign="left"><TextFieldElement name="damageParticipation" size="small" fullWidth /></Grid>
          </Grid>
          <Grid container spacing={1} sx={{ my: 1 }}>
            <Grid item xs={3} textAlign="left" sx={{ fontWeight: 'bold' }}>Opis učešća:</Grid>
            <Grid item xs={3} textAlign="left">
            <SelectElement
              name="description"
              fullWidth
              required
              size="small"
              options={[
                {
                  id: 'Nema',
                  label: 'Nema'
                },
                {
                  id: 'Apsolutno',
                  label: 'Apsolutno'
                },
                {
                  id: 'Relativno',
                  label: 'Relativno'
                },
              ]}
            />
            </Grid>
          </Grid>
          <Grid container spacing={1} sx={{ my: 1 }}>
            <Grid item xs={3} textAlign="left" sx={{ fontWeight: 'bold' }}>Uslovi korišćenja - link:</Grid>
            <Grid item xs={3} textAlign="left"><TextFieldElement name="termsAndConditions" size="small" fullWidth /></Grid>
          </Grid>
          <Grid container spacing={1} sx={{ my: 1 }}>
            <Grid item xs={12} textAlign="center" >
            <Button type="submit" color="success" variant="contained">Izmeni</Button>
            <Button variant="contained" color="primary" sx={{mx: 1}} onClick={() => navigate('/insurances/list')}>Nazad</Button>
            </Grid>
          </Grid>
      </FormContainer>
      </FormErrorProvider>
    </Container>
    </>
  );
}