import React, { useState, useEffect } from 'react';
import { useSearchParams, useNavigate, useLocation } from "react-router-dom";
import { Typography, Divider, Button, Grid, Container, TextField } from '@mui/material';
import axios from 'axios';
  
import AppContext from '../../components/AppContext';
import ErrorHandler from '../../components/ErrorHandler';
import Loading from '../../components/Loading';

import Config from '../../utils/Config';

import {TimestampToDate, TimestampToDateTime, TimestampToMillisNumber} from '../../utils/Helpers';

export default function View() {
  const navigate = useNavigate();
  const [queryParameters] = useSearchParams();
  const [data, setData] = useState(false);
  const [imageDashboardHeight, setImageDashboardHeight] = useState(100);
  
  const { appContext } = React.useContext(AppContext);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(Config.BASE_URL + '/admin/services/get?id=' + queryParameters.get('id'), {
          headers: {
            Authorization: 'Bearer ' + appContext.userToken, 
            'Cache-Control': 'no-cache',
          },
        });

        setData(response.data);
      } catch (error) {
        console.error(error);
        ErrorHandler(error.response, {'userToken': appContext.userToken, 'signOut': appContext.signOut});
      }
    };

    fetchData();
  }, []);
  
  if (data === false) {
    return (<Loading />);
  }

  if (!data) {
    return (
      <Container maxWidth="false" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 300 }}>
        <Typography variant="h4">Nema podataka</Typography>
      </Container>
    )
  }
  
  return (
    <>
    <Container maxWidth="false">
      <Grid container spacing={1} sx={{ my: 2 }}>
        <Typography variant="h4">{data.title}</Typography>
      </Grid>
      <Divider light />
      <Grid container spacing={1} sx={{ my: 1 }}>
        <Grid item xs={2} textAlign="left" sx={{ fontWeight: 'bold' }}>Vozilo:</Grid>
        <Grid item xs={3} textAlign="left">{data.car.garageNumber} - {data.car.registrationPlate} - {data.car.brand.name} {data.car.model}</Grid>
      </Grid>
      <Grid container spacing={1} sx={{ my: 1 }} className="FormRowOdd">
        <Grid item xs={2} textAlign="left" sx={{ fontWeight: 'bold' }}>Saradnik:</Grid>
        <Grid item xs={3} textAlign="left">{data.collaborator?.name}</Grid>
      </Grid>
      <Grid container spacing={1} sx={{ my: 1 }}>
        <Grid item xs={2} textAlign="left" sx={{ fontWeight: 'bold' }}>Garantni rok:</Grid>
        <Grid item xs={3} textAlign="left">{data.warrantyPeriod}</Grid>
      </Grid>
      <Grid container spacing={1} sx={{ my: 1 }} className="FormRowOdd">
        <Grid item xs={2} textAlign="left" sx={{ fontWeight: 'bold' }}>Status:</Grid>
        <Grid item xs={3} textAlign="left">{data.status}</Grid>
      </Grid>
      <Grid container spacing={1} sx={{ my: 1 }}>
        <Grid item xs={2} textAlign="left" sx={{ fontWeight: 'bold' }}>Datum:</Grid>
        <Grid item xs={3} textAlign="left">{TimestampToDate(data.date)}</Grid>
      </Grid>
      <Grid container spacing={1} sx={{ my: 1 }} className="FormRowOdd">
        <Grid item xs={2} textAlign="left" sx={{ fontWeight: 'bold' }}>Vremenski intervali:</Grid>
        <Grid item xs={3} textAlign="left">{data.time}</Grid>
      </Grid>
      <Grid container spacing={1} sx={{ my: 1 }} >
        <Grid item xs={2} textAlign="left" sx={{ fontWeight: 'bold' }}>Kilometraža:</Grid>
        <Grid item xs={3} textAlign="left">{data.mileage}</Grid>
      </Grid>
      <Grid container spacing={1} sx={{ my: 1 }} className="FormRowOdd">
        <Grid item xs={2} textAlign="left" sx={{ fontWeight: 'bold' }}>Kilometar sat:</Grid>
        <Grid item xs={3} textAlign="left">
          <img src={data.imageDashboard} height={imageDashboardHeight} onClick={() => imageDashboardHeight == null ? setImageDashboardHeight(100) : setImageDashboardHeight(null) } />
        </Grid>
      </Grid>
      <Grid container spacing={1} sx={{ my: 1 }}>
        <Grid item xs={2} textAlign="left" sx={{ fontWeight: 'bold' }}>Napomena:</Grid>
        <Grid item xs={3} textAlign="left">{data.note}</Grid>
      </Grid>
      <Grid container spacing={1} sx={{ my: 1 }} className="FormRowOdd">
        <Grid item xs={2} textAlign="left" sx={{ fontWeight: 'bold'}}>Korisnik:</Grid>
        <Grid item xs={3} textAlign="left">{data.user?.phone} - {data.user?.firstName} {data.user?.lastName}</Grid>
      </Grid>
    </Container>
    <Container maxWidth="false" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 20, marginBottom: 20 }}>
      <Button variant="contained" color="success" sx={{mx: 1}} onClick={() => navigate('/tires/edit?id=' + queryParameters.get('id'))}>Izmeni</Button>
      <Button variant="contained" color="primary" sx={{mx: 1}} onClick={() => navigate('/tires/list')}>Nazad</Button>
    </Container>
    </>
  );
}