import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import AppContext from './AppContext';

export default function DialogBox() {
  const { dialogBox, setDialogBox } = React.useContext(AppContext);

  const handleClose = () => {
    if (dialogBox.show !== 'forced') {
        setDialogBox({show: false});
    }
  };

  if (dialogBox.type == 'loadingBox') {
    return (
    <div>
      <Dialog open={dialogBox.show}>
        <DialogContent sx={{ color: '#42a5f5' }}>
          <CircularProgress color="inherit" />
        </DialogContent>
      </Dialog>
    </div>
    )
  } else if (dialogBox.type == 'loading') {
    return (
    <Backdrop
      sx={{ color: '#42a5f5', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={dialogBox.show}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
    )
  } else if (dialogBox.type == 'message') {
    return (
    <div>
    <Dialog
        open={dialogBox.show}
        onClose={handleClose} // this also handles background click 
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="xs"
        fullWidth
    >
        <DialogTitle id="alert-dialog-title">
        {dialogBox.title}
        </DialogTitle>
        <DialogContent>
            <DialogContentText id="alert-dialog-description">
            {dialogBox.message}
            </DialogContentText>
        </DialogContent>
        <DialogActions>
        <Button onClick={() => setDialogBox({show: false})} autoFocus>OK</Button>
        </DialogActions>
    </Dialog>
    </div>
    )
  } else {
    return null;
  }
}