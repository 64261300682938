import React, { useState, useEffect } from 'react';
import { useSearchParams, useNavigate, useLocation } from "react-router-dom";
import { Typography, Divider, Button, Grid, Container, ButtonGroup } from '@mui/material';
import axios from 'axios';
  
import AppContext from '../../components/AppContext';
import ErrorHandler from '../../components/ErrorHandler';
import Loading from '../../components/Loading';
import CarSubmenu from '../../components/CarSubmenu';

import Config from '../../utils/Config';
import {TimestampToDate} from '../../utils/Helpers';

export default function View() {
  const navigate = useNavigate();
  const [queryParameters] = useSearchParams();
  const [data, setData] = useState(false);

  const { appContext } = React.useContext(AppContext);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(Config.BASE_URL + '/admin/cars/get?id=' + queryParameters.get('id'), {
          headers: {
            Authorization: 'Bearer ' + appContext.userToken, 
            'Cache-Control': 'no-cache',
          },
        });

        setData(response.data);
      } catch (error) {
        console.error(error);
        ErrorHandler(error.response, {'userToken': appContext.userToken, 'signOut': appContext.signOut});
      }
    };

    fetchData();
  }, []);

    console.log(queryParameters.get('id'))

    console.log(data.length)
    console.log(data)
  
    if (data === false) {
      return (<Loading />);
    }

    if (!data) {
      return (
        <Container maxWidth="false" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 300 }}>
          <Typography variant="h4">Nema podataka za dato vozilo</Typography>
        </Container>
      )
    }
  
    return (
      <>
      <Container maxWidth="false">
        <Grid container spacing={1} sx={{ my: 2 }}>
          <Typography variant="h4">{data.garageNumber} - {data.registrationPlate} - {data.brand.name} {data.model}</Typography>
        </Grid>
        <Divider light />
        <CarSubmenu />
        <Grid container spacing={1} sx={{ my: 1 }}>
          <Grid item xs={2} textAlign="left" sx={{ fontWeight: 'bold' }}>Garažni broj:</Grid>
          <Grid item xs={3} textAlign="left">{data.garageNumber}</Grid>
        </Grid>
        <Grid container spacing={1} sx={{ my: 1 }} className="FormRowOdd">
          <Grid item xs={2} textAlign="left" sx={{ fontWeight: 'bold' }}>Status vozila:</Grid>
          <Grid item xs={3} textAlign="left">{data.status}</Grid>
        </Grid>
        <Grid container spacing={1} sx={{ my: 1 }}>
          <Grid item xs={2} textAlign="left" sx={{ fontWeight: 'bold' }}>Zakupac:</Grid>
          <Grid item xs={3} textAlign="left">{data.client.name}</Grid>
        </Grid>
        <Grid container spacing={1} sx={{ my: 1 }} className="FormRowOdd">
          <Grid item xs={2} textAlign="left" sx={{ fontWeight: 'bold' }}>Broj ugovora:</Grid>
          <Grid item xs={3} textAlign="left">{data.contractNumber}</Grid>
        </Grid>
        <Grid container spacing={1} sx={{ my: 1 }}>
          <Grid item xs={2} textAlign="left" sx={{ fontWeight: 'bold' }}>Status ugovora:</Grid>
          <Grid item xs={3} textAlign="left">{data.contractStatus}</Grid>
        </Grid>
        <Grid container spacing={1} sx={{ my: 1 }} className="FormRowOdd">
          <Grid item xs={2} textAlign="left" sx={{ fontWeight: 'bold' }}>Datum početka ugovora:</Grid>
          <Grid item xs={3} textAlign="left">{TimestampToDate(data.contractStartDate)}</Grid>
        </Grid>
        <Grid container spacing={1} sx={{ my: 1 }}>
          <Grid item xs={2} textAlign="left" sx={{ fontWeight: 'bold' }}>Tip vozila:</Grid>
          <Grid item xs={3} textAlign="left">{data.type}</Grid>
        </Grid>
        <Grid container spacing={1} sx={{ my: 1 }} className="FormRowOdd">
          <Grid item xs={2} textAlign="left" sx={{ fontWeight: 'bold' }}>Marka:</Grid>
          <Grid item xs={3} textAlign="left">{data.brand.name}</Grid>
        </Grid>
        <Grid container spacing={1} sx={{ my: 1 }}>
          <Grid item xs={2} textAlign="left" sx={{ fontWeight: 'bold' }}>Model:</Grid>
          <Grid item xs={3} textAlign="left">{data.model}</Grid>
        </Grid>
        <Grid container spacing={1} sx={{ my: 1 }} className="FormRowOdd">
          <Grid item xs={2} textAlign="left" sx={{ fontWeight: 'bold' }}>Godina proizvodnje:</Grid>
          <Grid item xs={3} textAlign="left">{data.manufactureYear}</Grid>
        </Grid>
        <Grid container spacing={1} sx={{ my: 1 }}>
          <Grid item xs={2} textAlign="left" sx={{ fontWeight: 'bold' }}>Registarska oznaka:</Grid>
          <Grid item xs={3} textAlign="left">{data.registrationPlate}</Grid>
        </Grid>
        <Grid container spacing={1} sx={{ my: 1 }} className="FormRowOdd">
          <Grid item xs={2} textAlign="left" sx={{ fontWeight: 'bold' }}>Broj saobraćajne dozvole:</Grid>
          <Grid item xs={3} textAlign="left">{data.trafficLicenseNumber}</Grid>
        </Grid>
        <Grid container spacing={1} sx={{ my: 1 }}>
          <Grid item xs={2} textAlign="left" sx={{ fontWeight: 'bold' }}>Izdata od:</Grid>
          <Grid item xs={3} textAlign="left">{data.issuedBy}</Grid>
        </Grid>
        <Grid container spacing={1} sx={{ my: 1 }} className="FormRowOdd">
          <Grid item xs={2} textAlign="left" sx={{ fontWeight: 'bold' }}>Broj šasije:</Grid>
          <Grid item xs={3} textAlign="left">{data.chassisNumber}</Grid>
        </Grid>
        <Grid container spacing={1} sx={{ my: 1 }}>
          <Grid item xs={2} textAlign="left" sx={{ fontWeight: 'bold' }}>Broj motora:</Grid>
          <Grid item xs={3} textAlign="left">{data.engineNumber}</Grid>
        </Grid>
        <Grid container spacing={1} sx={{ my: 1 }} className="FormRowOdd">
          <Grid item xs={2} textAlign="left" sx={{ fontWeight: 'bold' }}>Radna zapremina:</Grid>
          <Grid item xs={3} textAlign="left">{data.engineDisplacement}</Grid>
        </Grid>
        <Grid container spacing={1} sx={{ my: 1 }}>
          <Grid item xs={2} textAlign="left" sx={{ fontWeight: 'bold' }}>Snaga motora u KW:</Grid>
          <Grid item xs={3} textAlign="left">{data.enginePower}</Grid>
        </Grid>
        <Grid container spacing={1} sx={{ my: 1 }} className="FormRowOdd">
          <Grid item xs={2} textAlign="left" sx={{ fontWeight: 'bold' }}>Broj mesta za sedenje:</Grid>
          <Grid item xs={3} textAlign="left">{data.seatsNumber}</Grid>
        </Grid>
        <Grid container spacing={1} sx={{ my: 1 }}>
          <Grid item xs={2} textAlign="left" sx={{ fontWeight: 'bold' }}>Boja:</Grid>
          <Grid item xs={3} textAlign="left">{data.color}</Grid>
        </Grid>
        <Grid container spacing={1} sx={{ my: 1 }} className="FormRowOdd">
          <Grid item xs={2} textAlign="left" sx={{ fontWeight: 'bold' }}>Masa praznog vozila:</Grid>
          <Grid item xs={3} textAlign="left">{data.weight}</Grid>
        </Grid>
        <Grid container spacing={1} sx={{ my: 1 }}>
          <Grid item xs={2} textAlign="left" sx={{ fontWeight: 'bold' }}>Dozvoljena masa vozila:</Grid>
          <Grid item xs={3} textAlign="left">{data.maximumLoad}</Grid>
        </Grid>
        <Grid container spacing={1} sx={{ my: 1 }} className="FormRowOdd">
          <Grid item xs={2} textAlign="left" sx={{ fontWeight: 'bold' }}>Dodatna oprema:</Grid>
          <Grid item xs={3} textAlign="left">{data.additionalEquipment}</Grid>
        </Grid>
        <Grid container spacing={1} sx={{ my: 1 }}>
          <Grid item xs={2} textAlign="left" sx={{ fontWeight: 'bold' }}>Napomena 1:</Grid>
          <Grid item xs={3} textAlign="left">{data.note1}</Grid>
        </Grid>
        <Grid container spacing={1} sx={{ my: 1 }} className="FormRowOdd">
          <Grid item xs={2} textAlign="left" sx={{ fontWeight: 'bold' }}>Napomena 2:</Grid>
          <Grid item xs={3} textAlign="left">{data.note2}</Grid>
        </Grid>
        <Grid container spacing={1} sx={{ my: 1 }}>
          <Grid item xs={2} textAlign="left" sx={{ fontWeight: 'bold' }}>Napomena 3:</Grid>
          <Grid item xs={3} textAlign="left">{data.note3}</Grid>
        </Grid>
        <Grid container spacing={1} sx={{ my: 1 }} className="FormRowOdd">
          <Grid item xs={2} textAlign="left" sx={{ fontWeight: 'bold' }}>Napomena 4:</Grid>
          <Grid item xs={3} textAlign="left">{data.note4}</Grid>
        </Grid>
        <Grid container spacing={1} sx={{ my: 1 }}>
          <Grid item xs={2} textAlign="left" sx={{ fontWeight: 'bold' }}>Napomena 5:</Grid>
          <Grid item xs={3} textAlign="left">{data.note5}</Grid>
        </Grid>
        <Grid container spacing={1} sx={{ my: 1 }} className="FormRowOdd">
          <Grid item xs={2} textAlign="left" sx={{ fontWeight: 'bold' }}>Ugovorena kilometraža period:</Grid>
          <Grid item xs={3} textAlign="left">{data.allowedMileagePeriod}</Grid>
        </Grid>
        <Grid container spacing={1} sx={{ my: 1 }}>
          <Grid item xs={2} textAlign="left" sx={{ fontWeight: 'bold' }}>Ugovorena mesečna kilometraža:</Grid>
          <Grid item xs={3} textAlign="left">{data.allowedMileageMonth}</Grid>
        </Grid>
        <Grid container spacing={1} sx={{ my: 1 }} className="FormRowOdd">
          <Grid item xs={2} textAlign="left" sx={{ fontWeight: 'bold' }}>Ugovorena godišnja kilometraža:</Grid>
          <Grid item xs={3} textAlign="left">{data.allowedMileageYear}</Grid>
        </Grid>
        <Grid container spacing={1} sx={{ my: 1 }}>
          <Grid item xs={2} textAlign="left" sx={{ fontWeight: 'bold' }}>Tip goriva:</Grid>
          <Grid item xs={3} textAlign="left">{data.fuelType}</Grid>
        </Grid>
        <Grid container spacing={1} sx={{ my: 1 }} className="FormRowOdd">
          <Grid item xs={2} textAlign="left" sx={{ fontWeight: 'bold' }}>Vlasnik:</Grid>
          <Grid item xs={3} textAlign="left">{data.owner}</Grid>
        </Grid>
        <Grid container spacing={1} sx={{ my: 1 }}>
          <Grid item xs={2} textAlign="left" sx={{ fontWeight: 'bold' }}>Hotel:</Grid>
          <Grid item xs={3} textAlign="left">{data.tireHotel}</Grid>
        </Grid>
      </Container>
      <Container maxWidth="false" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 20, marginBottom: 20 }}>
        <Button variant="contained" color="success" sx={{mx: 1}} onClick={() => navigate('/cars/edit?id=' + queryParameters.get('id'))}>Izmeni</Button>
        <Button variant="contained" color="primary" sx={{mx: 1}} onClick={() => navigate('/cars/list')}>Nazad</Button>
      </Container>
      </>
    );
}

