import React from 'react';
import { Container } from "@mui/material";
  
export default function Loading() {

  return (
    <Container maxWidth="false" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 300 }}>
        <img src={'/images/loading.gif'} />
    </Container>
    ); 
  
}
