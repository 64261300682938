import React from 'react';
import { useSearchParams, useLocation, useNavigate } from "react-router-dom";
import { Grid, ButtonGroup, Button } from "@mui/material";
  
import { ROLES } from '../utils/Constants.js';

export default function UserSubmenu({user}) {
    const navigate = useNavigate();
    const location = useLocation();
    const [queryParameters] = useSearchParams();

    const subPage = location.pathname.match(/\/.*\/([^/]+)/);

    return (
    <Grid container spacing={1} sx={{ my: 2 }}>
        <ButtonGroup variant="text" aria-label="outlined primary button group">
        <Button variant={subPage[1] == "view" ? "contained" : ""} onClick={() => {navigate('/users/view?id=' + queryParameters.get('id'))}}>Podaci</Button>
        {user.role?.roleId == ROLES.FLEET_MANAGER && (
        <Button variant={subPage[1] == "assigned" ? "contained" : ""} onClick={() => {navigate('/users/assigned?id=' + queryParameters.get('id'))}}>Dodeljena vozila</Button>
        )}
        </ButtonGroup>
    </Grid>
    )
}