import React, { useState, useEffect } from 'react';
import { useSearchParams, useNavigate, useLocation } from "react-router-dom";
import { Typography, Divider, Button, Grid, Container, TextField } from '@mui/material';
import axios from 'axios';
  
import AppContext from '../../components/AppContext';
import ErrorHandler from '../../components/ErrorHandler';
import Loading from '../../components/Loading';

import Config from '../../utils/Config';

export default function View() {
  const navigate = useNavigate();
  const [queryParameters] = useSearchParams();
  const [data, setData] = useState(false);

  const { appContext } = React.useContext(AppContext);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(Config.BASE_URL + '/admin/collaborators/get?id=' + queryParameters.get('id'), {
          headers: {
            Authorization: 'Bearer ' + appContext.userToken, 
            'Cache-Control': 'no-cache',
          },
        });

        setData(response.data);
      } catch (error) {
        console.error(error);
        ErrorHandler(error.response, {'userToken': appContext.userToken, 'signOut': appContext.signOut});
      }
    };

    fetchData();
  }, []);

    console.log(queryParameters.get('id'))

    console.log(data.length)
    console.log(data)
  
    if (data === false) {
      return (<Loading />);
    }

    if (!data) {
      return (
        <Container maxWidth="false" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 300 }}>
          <Typography variant="h4">Nema podataka za datog saradnika</Typography>
        </Container>
      )
    }
  
    return (
      <>
      <Container maxWidth="false">
        <Grid container spacing={1} sx={{ my: 2 }}>
          <Typography variant="h4">{data.name}</Typography>
        </Grid>
        <Divider light />
        <Grid container spacing={1} sx={{ my: 1 }}>
          <Grid item xs={2} textAlign="left" sx={{ fontWeight: 'bold' }}>Poslovno ime:</Grid>
          <Grid item xs={3} textAlign="left">{data.fullName}</Grid>
        </Grid>
        <Grid container spacing={1} sx={{ my: 1 }} className="FormRowOdd">
          <Grid item xs={2} textAlign="left" sx={{ fontWeight: 'bold' }}>Kontakt osoba:</Grid>
          <Grid item xs={3} textAlign="left">{data.contactPerson}</Grid>
        </Grid>
        <Grid container spacing={1} sx={{ my: 1 }}>
          <Grid item xs={2} textAlign="left" sx={{ fontWeight: 'bold' }}>Telefon:</Grid>
          <Grid item xs={3} textAlign="left">{data.phoneNumber1}</Grid>
        </Grid>
        <Grid container spacing={1} sx={{ my: 1 }} className="FormRowOdd">
          <Grid item xs={2} textAlign="left" sx={{ fontWeight: 'bold' }}>Mobilni telefon:</Grid>
          <Grid item xs={3} textAlign="left">{data.mobileNumber1}</Grid>
        </Grid>
        <Grid container spacing={1} sx={{ my: 1 }}>
          <Grid item xs={2} textAlign="left" sx={{ fontWeight: 'bold' }}>Zemlja:</Grid>
          <Grid item xs={3} textAlign="left">{data.country}</Grid>
        </Grid>
        <Grid container spacing={1} sx={{ my: 1 }} className="FormRowOdd">
          <Grid item xs={2} textAlign="left" sx={{ fontWeight: 'bold' }}>Tip:</Grid>
          <Grid item xs={3} textAlign="left">{data.type}</Grid>
        </Grid>
        <Grid container spacing={1} sx={{ my: 1 }}>
          <Grid item xs={2} textAlign="left" sx={{ fontWeight: 'bold' }}>Pravni oblik:</Grid>
          <Grid item xs={3} textAlign="left">{data.legalForm}</Grid>
        </Grid>
        <Grid container spacing={1} sx={{ my: 1 }} className="FormRowOdd">
          <Grid item xs={2} textAlign="left" sx={{ fontWeight: 'bold' }}>PIB:</Grid>
          <Grid item xs={3} textAlign="left">{data.taxNumber}</Grid>
        </Grid>
        <Grid container spacing={1} sx={{ my: 1 }}>
          <Grid item xs={2} textAlign="left" sx={{ fontWeight: 'bold' }}>Adresa:</Grid>
          <Grid item xs={3} textAlign="left">{data.address}</Grid>
        </Grid>
        <Grid container spacing={1} sx={{ my: 1 }} className="FormRowOdd">
          <Grid item xs={2} textAlign="left" sx={{ fontWeight: 'bold' }}>Matični broj:</Grid>
          <Grid item xs={3} textAlign="left">{data.idNumber}</Grid>
        </Grid>
        <Grid container spacing={1} sx={{ my: 1 }}>
          <Grid item xs={2} textAlign="left" sx={{ fontWeight: 'bold' }}>Grad:</Grid>
          <Grid item xs={3} textAlign="left">{data.city}</Grid>
        </Grid>
        <Grid container spacing={1} sx={{ my: 1 }} className="FormRowOdd">
          <Grid item xs={2} textAlign="left" sx={{ fontWeight: 'bold' }}>Poštanski broj:</Grid>
          <Grid item xs={3} textAlign="left">{data.zipCode}</Grid>
        </Grid>
        <Grid container spacing={1} sx={{ my: 1 }}>
          <Grid item xs={2} textAlign="left" sx={{ fontWeight: 'bold' }}>Radno vreme:</Grid>
          <Grid item xs={3} textAlign="left">{data.workingHours}</Grid>
        </Grid>
      </Container>
      <Container maxWidth="false" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 20, marginBottom: 20 }}>
        <Button variant="contained" color="success" sx={{mx: 1}} onClick={() => navigate('/collaborators/edit?id=' + queryParameters.get('id'))}>Izmeni</Button>
        <Button variant="contained" color="primary" sx={{mx: 1}} onClick={() => navigate('/collaborators/list')}>Nazad</Button>
      </Container>
      </>
    );
}