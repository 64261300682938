import React, { useState, useEffect } from 'react';
import { useSearchParams, useNavigate, useLocation } from "react-router-dom";
import { Paper, Typography, Divider, Button, Grid, Container, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TablePagination } from '@mui/material';
import axios from 'axios';

import AppContext from '../../components/AppContext';
import ErrorHandler from '../../components/ErrorHandler';
import Loading from '../../components/Loading';
import CarSubmenu from '../../components/CarSubmenu';
import TablePaginationActions from '../../components/TablePaginationActions';

import Config from '../../utils/Config';
import {TimestampToDate, TimestampToDateTime} from '../../utils/Helpers';

export default function View() {
  const navigate = useNavigate();
  const [queryParameters] = useSearchParams();
  const [data, setData] = useState(false);
  const [rows, setRows] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const { appContext } = React.useContext(AppContext);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(Config.BASE_URL + '/admin/cars/get?id=' + queryParameters.get('id'), {
          headers: {
            Authorization: 'Bearer ' + appContext.userToken, 
            'Cache-Control': 'no-cache',
          },
        });

        setData(response.data);
      } catch (error) {
        console.error(error);
        ErrorHandler(error.response, {'userToken': appContext.userToken, 'signOut': appContext.signOut});
      }
    };

    const fetchList = async () => {
      try {
        const response = await axios.get(Config.BASE_URL + '/admin/cars/services?id=' + queryParameters.get('id'), {
          headers: {
            Authorization: 'Bearer ' + appContext.userToken, 
            'Cache-Control': 'no-cache',
          },
        });

        setRows(response.data);
      } catch (error) {
        console.error(error);
        ErrorHandler(error.response, {'userToken': appContext.userToken, 'signOut': appContext.signOut});
      }
    };

    fetchData();
    fetchList();
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

    console.log(queryParameters.get('id'))

    console.log(data.length)
    console.log(data)
    console.log(rows)
  
    if (data === false || rows === false) {
      return (<Loading />);
    }

    if (!data || !rows) {
      return (
        <Container maxWidth="false" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 300 }}>
          <Typography variant="h4">Nema podataka za dato vozilo</Typography>
        </Container>
      )
    }
  
    return (
      <>
      <Container maxWidth="false">
        <Grid container spacing={1} sx={{ my: 2 }}>
          <Typography variant="h4">{data.garageNumber} - {data.registrationPlate} - {data.brand.name} {data.model}</Typography>
        </Grid>
        <Divider light />
        <CarSubmenu />
        <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
              labelRowsPerPage="Redova po stranici"
          />
          <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead color="secondary">
              <TableRow>
                  <TableCell>Naziv</TableCell>
                  <TableCell align="left">Zakupac</TableCell>
                  <TableCell align="left">Saradnik</TableCell>
                  <TableCell align="left">Status</TableCell>
                  <TableCell align="left">Vrsta</TableCell>
                  <TableCell align="left">Grad</TableCell>
                  <TableCell align="left">Datum</TableCell>
                  <TableCell align="left">Upisan</TableCell>
                  <TableCell align="left"></TableCell>
              </TableRow>
              </TableHead>
              <TableBody>
              {rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => (
                  <TableRow
                  key={row.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 }, 
                        '&:nth-of-type(even)': { backgroundColor: "#f6f6f6" },
                        '&:hover': { backgroundColor: "#fffbc7", cursor: 'pointer' } }}
                  onClick={() => navigate('/services/view?id=' + row.id)}
                  >
                  <TableCell component="th" scope="row">{row.title}</TableCell>
                  <TableCell align="left">{row.clientName}</TableCell>
                  <TableCell align="left">{row.collaboratorName}</TableCell>
                  <TableCell align="left">{row.status}</TableCell>
                  <TableCell align="left">{row.category}</TableCell>
                  <TableCell align="left">{row.city}</TableCell>
                  <TableCell align="left">{TimestampToDate(row.date)}</TableCell>
                  <TableCell align="left">{TimestampToDateTime(row.created)}</TableCell>
                  <TableCell align="left"><Button variant="text" color="secondary" size="small" sx={{m:0, p:0}} onClick={(e) => { navigate('/services/edit?id=' + row.id); e.stopPropagation(); }}>Izmeni</Button></TableCell>
                  </TableRow>
              ))}
              </TableBody>
          </Table>
          </TableContainer>
          <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
              labelRowsPerPage="Redova po stranici"
          />
      </Container>
      </>
    );
}

