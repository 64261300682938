import React, { useState, useEffect } from 'react';
import { useSearchParams, useNavigate, useLocation } from "react-router-dom";
import { Typography, Divider, Button, Grid, Container, TextField } from '@mui/material';
import {useForm, FormErrorProvider, FormContainer, TextFieldElement, SelectElement, AutocompleteElement} from 'react-hook-form-mui'
import axios from 'axios';
  
import AppContext from '../../components/AppContext';
import ErrorHandler from '../../components/ErrorHandler';
import Loading from '../../components/Loading';

import Config from '../../utils/Config';

import { areStringsEqual } from '../../utils/Helpers';
import { ROLES } from '../../utils/Constants';

export default function Edit() {
  const navigate = useNavigate();
  const { appContext, setDialogBox } = React.useContext(AppContext);

  const [queryParameters] = useSearchParams();
  const [data, setData] = useState(false);
  const [clients, setClients] = useState([]);
  const [roles, setRoles] = useState([]);
  const [cars, setCars] = useState([]);
  const [loading, setLoading] = useState(false);
  const [clientId, setClientId] = useState(null);

  const formContext = useForm();

  const fetchCarsByClient = async (clientId) => {
    try {
      setLoading(true);

      const response = await axios.get(Config.BASE_URL + '/admin/cars/listByClient?id=' + clientId, {
        headers: {
          Authorization: 'Bearer ' + appContext.userToken, 
          'Cache-Control': 'no-cache',
        },
      });

      setLoading(false);

      var cars = []

      if (response.data) {
        {response.data.map((item) => 
          cars.push({id: item.carId, label: item.garageNumber + ' - ' + item.registrationPlate + ' - ' + item.brand.name + ' ' + item.model})
        )}

        setCars(cars);
      }
    } catch (error) {
      console.error(error);
      ErrorHandler(error.response, {'userToken': appContext.userToken, 'signOut': appContext.signOut});
    }
  };

  const fetchCars = async () => {
    try {
      const response = await axios.get(Config.BASE_URL + '/admin/cars/list', {
        headers: {
          Authorization: 'Bearer ' + appContext.userToken, 
          'Cache-Control': 'no-cache',
        },
      });

      for (const dataId in response.data) {
        response.data[dataId]["label"] = response.data[dataId]["garageNumber"] + " - " + response.data[dataId]["registrationPlate"] + " - " + response.data[dataId]["brandName"] + " " + response.data[dataId]["model"];
      }

      setCars(response.data);
    } catch (error) {
      console.error(error);
      ErrorHandler(error.response, {'userToken': appContext.userToken, 'signOut': appContext.signOut});
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(Config.BASE_URL + '/admin/users/get?id=' + queryParameters.get('id'), {
          headers: {
            Authorization: 'Bearer ' + appContext.userToken, 
            'Cache-Control': 'no-cache',
          },
        });
        
        console.log("getdata")

        // prepare for form inputs
        response.data.clientId = response.data.client.clientId;
        response.data.carId = response.data.selectedCar?.carId;
        response.data.roleId = response.data.role?.roleId;
        response.data.newPassword = response.data.password;
        response.data.newPassword2 = response.data.password;

        // remove not needed data
        delete response.data.client;
        delete response.data.selectedCar;

        setData(response.data);
      } catch (error) {
        console.error(error);
        ErrorHandler(error.response, {'userToken': appContext.userToken, 'signOut': appContext.signOut});
      }
    };

    const fetchClients = async () => {
      try {
        const response = await axios.get(Config.BASE_URL + '/admin/clients/list', {
          headers: {
            Authorization: 'Bearer ' + appContext.userToken, 
            'Cache-Control': 'no-cache',
          },
        });

        for (const data in response.data) {
          response.data[data]["label"] = response.data[data]["name"] + " - " + (response.data[data]["idNumber"] ? response.data[data]["idNumber"] : response.data[data]["jmbgnumber"]);
        }

        setClients(response.data);
      } catch (error) {
        console.error(error);
        ErrorHandler(error.response, {'userToken': appContext.userToken, 'signOut': appContext.signOut});
      }
    };

    const fetchRoles = async () => {
      try {
        const response = await axios.get(Config.BASE_URL + '/admin/users/getRoles', {
          headers: {
            Authorization: 'Bearer ' + appContext.userToken, 
            'Cache-Control': 'no-cache',
          },
        });

        var roles = []

        if (response.data) {
          {response.data.map((item) => 
            roles.push({id: item.roleId, label: item.name, disabled: appContext.userData.role.roleId != ROLES.SUPERADMINISTRATOR && item.roleId == ROLES.SUPERADMINISTRATOR ? true : false})
          )}

          setRoles(roles);
        }
      } catch (error) {
        console.error(error);
        ErrorHandler(error.response, {'userToken': appContext.userToken, 'signOut': appContext.signOut});
      }
    };

    fetchData();
    fetchClients();
    fetchRoles();    
  }, []);

  useEffect(() => {
    // disable "authenticated user" checkbox
    /*
    const authenticatedCheckboxElement = document.querySelector('.PrivateSwitchBase-input[value="2"]');

    if (authenticatedCheckboxElement) {
      authenticatedCheckboxElement.disabled = true;
    }*/
    
    if (data) {
      formContext.reset(data); // clears default values cache
      setClientId(data.clientId);
    }

  }, [data]);

  useEffect(() => {
    if (data) { // execute after data loads
      if (clientId) {
        fetchCarsByClient(clientId);
      } else {
        fetchCars();
        formContext.setValue('carId', null);
      }
    }
  }, [clientId]);

  const handleFormSubmit = async (formData) => {
    console.log("handleFormSubmit")
    console.log(formData)

    formData.phone = formData.phone.replace(/^\+/, ''); // remove + from beginning
    formData.phone = formData.phone.replace(/^0/, '381'); // replace 0 with international

    if (!areStringsEqual(formData.newPassword, formData.newPassword2)) {
      setDialogBox({show: true, type: 'message', title: null, message: "Unesite istu šifru u oba polja"});
    } else {
      try {
        const response = await axios.post(Config.BASE_URL + '/admin/users/edit', formData, {
          headers: {
            Authorization: 'Bearer ' + appContext.userToken, 
          }
        });

        //console.log('Response:', response);

        navigate('/users/list');
      } catch (error) {
        console.error('Error:', error);
        ErrorHandler(error.response, {'userToken': appContext.userToken, 'signOut': appContext.signOut});
      }
    }
  };

  if (data === false) {
    return (<Loading />);
  }

  if (!data) {
    return (
      <Container maxWidth="false" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 300 }}>
        <Typography variant="h4">Nema podataka za datog korisnika</Typography>
      </Container>
    )
  }

  return (
    <>
    <Container maxWidth="false">
      <Grid container spacing={1} sx={{ my: 2 }}>
        <Typography variant="h4">Izmena korisnika {data.firstName} {data.lastName}</Typography>
      </Grid>
      <Divider light />
      <FormErrorProvider
        onError={(error) => {
          if (error.type === 'required') {
            return 'Popunite obavezno polje'
          } else if (error.type === 'pattern') {
            return 'Unesite ispravnu e-mail adresu'
          }
          return error?.message
        }}
      >
        <FormContainer     
          formContext={formContext}
          defaultValues={data}
          onSuccess={formData => handleFormSubmit(formData)}>
          <Grid container spacing={1} sx={{ my: 1 }}>
            <Grid item xs={3} textAlign="left" sx={{ fontWeight: 'bold' }}>Kompanija: <Typography variant="h6" display="inline-block" color="red">*</Typography></Grid>
            <Grid item xs={3} textAlign="left">
              <AutocompleteElement
                name="clientId"
                matchId
                fullWidth
                required
                options={clients}
                control={formContext.control}
                autocompleteProps={{
                  //readOnly: appContext.userData.role.roleId == ROLES.SUPERADMINISTRATOR ? false : true,         
                  onChange: (e, value) => {
                    if (value) {
                      setClientId(value.id);
                    } else {
                      setClientId(null);
                    }
                  }
                }}
                textFieldProps={{
                  InputProps: { size: "small" },
                }}
                /*
                textFieldProps={{
                  InputProps: {
                    className: appContext.userData.role.roleId == ROLES.SUPERADMINISTRATOR ? undefined : 'Mui-disabled',
                  }
                }}
                */
            />
            </Grid>
          </Grid>
          <Grid container spacing={1} sx={{ my: 1 }}>
            <Grid item xs={3} textAlign="left" sx={{ fontWeight: 'bold' }}>Odabrano vozilo:</Grid>
            <Grid item xs={3} textAlign="left">
              <AutocompleteElement
                name="carId"
                matchId
                fullWidth
                loading={loading}
                options={cars}
                control={formContext.control}
                autocompleteProps={{
                  onChange: (e, value) => {
                    if (!clientId && value) {
                      // set client company for selected car
                      formContext.setValue('clientId', value.clientId);
                      setClientId(value.clientId);
                    }
                  }
                }}             
                textFieldProps={{
                  InputProps: { size: "small" },
                }} 
                />
            </Grid>
          </Grid>
          <Grid container spacing={1} sx={{ my: 1 }}>
            <Grid item xs={3} textAlign="left" sx={{ fontWeight: 'bold' }}>Telefon: <Typography variant="h6" display="inline-block" color="red">*</Typography></Grid>
            <Grid item xs={3} textAlign="left"><TextFieldElement name="phone" size="small" fullWidth required /></Grid>
          </Grid>
          <Grid container spacing={1} sx={{ my: 1 }}>
            <Grid item xs={3} textAlign="left" sx={{ fontWeight: 'bold' }}>E-mail: <Typography variant="h6" display="inline-block" color="red">*</Typography></Grid>
            <Grid item xs={3} textAlign="left"><TextFieldElement name="email" size="small" type="email" fullWidth required /></Grid>
          </Grid>
          <Grid container spacing={1} sx={{ my: 1 }}>
            <Grid item xs={3} textAlign="left" sx={{ fontWeight: 'bold' }}>Šifra:</Grid>
            <Grid item xs={3} textAlign="left"><TextFieldElement name="newPassword" size="small" type="password" fullWidth /></Grid>
          </Grid>
          <Grid container spacing={1} sx={{ my: 1 }}>
            <Grid item xs={3} textAlign="left" sx={{ fontWeight: 'bold' }}>Ponovite šifru:</Grid>
            <Grid item xs={3} textAlign="left"><TextFieldElement name="newPassword2" size="small" type="password" fullWidth /></Grid>
          </Grid>
          <Grid container spacing={1} sx={{ my: 1 }}>
            <Grid item xs={3} textAlign="left" sx={{ fontWeight: 'bold' }}>Aktivan:</Grid>
            <Grid item xs={3} textAlign="left">
            <SelectElement
              name="active"
              fullWidth
              size="small"
              options={[
                {
                  id: true,
                  label: 'DA',
                  
                },
                {
                  id: false,
                  label: 'NE',
                },
              ]}
            />
            </Grid>
          </Grid>
          <Grid container spacing={1} sx={{ my: 1 }}>
            <Grid item xs={3} textAlign="left" sx={{ fontWeight: 'bold' }}>Uloga: <Typography variant="h6" display="inline-block" color="red">*</Typography></Grid>
            <Grid item xs={3} textAlign="left">
            <SelectElement
              name="roleId"
              fullWidth
              size="small"
              required
              options={roles}
              type="number"
              SelectProps={{
                readOnly: appContext.userData.role.roleId != ROLES.SUPERADMINISTRATOR && data.roleId == ROLES.SUPERADMINISTRATOR ? true : false,
                className: appContext.userData.role.roleId != ROLES.SUPERADMINISTRATOR && data.roleId == ROLES.SUPERADMINISTRATOR ? 'Mui-disabled' : undefined,
              }}
            />
            </Grid>
          </Grid>
          <Grid container spacing={1} sx={{ my: 1 }}>
            <Grid item xs={3} textAlign="left" sx={{ fontWeight: 'bold' }}>Ime: <Typography variant="h6" display="inline-block" color="red">*</Typography></Grid>
            <Grid item xs={3} textAlign="left"><TextFieldElement name="firstName" size="small" fullWidth required /></Grid>
          </Grid>
          <Grid container spacing={1} sx={{ my: 1 }}>
            <Grid item xs={3} textAlign="left" sx={{ fontWeight: 'bold' }}>Prezime <Typography variant="h6" display="inline-block" color="red">*</Typography></Grid>
            <Grid item xs={3} textAlign="left"><TextFieldElement name="lastName" size="small" fullWidth required /></Grid>
          </Grid>
          <Grid container spacing={1} sx={{ my: 1 }}>
            <Grid item xs={3} textAlign="left" sx={{ fontWeight: 'bold' }}>Adresa:</Grid>
            <Grid item xs={3} textAlign="left"><TextFieldElement name="address" size="small" fullWidth /></Grid>
          </Grid>
          <Grid container spacing={1} sx={{ my: 1 }}>
            <Grid item xs={3} textAlign="left" sx={{ fontWeight: 'bold' }}>Grad:</Grid>
            <Grid item xs={3} textAlign="left"><TextFieldElement name="city" size="small" fullWidth /></Grid>
          </Grid>
          <Grid container spacing={1} sx={{ my: 1 }}>
            <Grid item xs={12} textAlign="center" >
            <Button type="submit" color="success" variant="contained">Izmeni</Button>
            <Button variant="contained" color="primary" sx={{mx: 1}} onClick={() => navigate('/users/list')}>Nazad</Button>
            </Grid>
          </Grid>
      </FormContainer>
      </FormErrorProvider>
    </Container>
    </>
  );
}