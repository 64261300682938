import React, { useState, useEffect } from 'react';
import { useSearchParams, useNavigate, useLocation } from "react-router-dom";
import { Typography, Divider, Button, Grid, Container, Box } from '@mui/material';
import {FormErrorProvider, FormContainer, TextFieldElement, SelectElement, CheckboxElement, AutocompleteElement} from 'react-hook-form-mui'
import axios from 'axios';
  
import AppContext from '../../components/AppContext';
import ErrorHandler from '../../components/ErrorHandler';
import Loading from '../../components/Loading';

import Config from '../../utils/Config';

export default function Edit() {
  const navigate = useNavigate();

  const [queryParameters] = useSearchParams();
  const [data, setData] = useState(false);
  const [clients, setClients] = useState([]);
  const [brands, setBrands] = useState([]);

  const { appContext } = React.useContext(AppContext);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(Config.BASE_URL + '/admin/cars/get?id=' + queryParameters.get('id'), {
          headers: {
            Authorization: 'Bearer ' + appContext.userToken, 
            'Cache-Control': 'no-cache',
          },
        });
        
        // prepare for form inputs
        response.data.clientId = response.data.client.clientId;
        response.data.brandId = response.data.brand.brandId;
        response.data.brandName = response.data.brand.name;
        
        // remove not needed data
        delete response.data.client;
        delete response.data.brand;
        
        //console.log("getdata")
        //console.log(response.data)

        setData(response.data);
      } catch (error) {
        console.error(error);
        ErrorHandler(error.response, {'userToken': appContext.userToken, 'signOut': appContext.signOut});
      }
    };

    const fetchClients = async () => {
      try {
        const response = await axios.get(Config.BASE_URL + '/admin/clients/list', {
          headers: {
            Authorization: 'Bearer ' + appContext.userToken, 
            'Cache-Control': 'no-cache',
          },
        });

        for (const data in response.data) {
          response.data[data]["label"] = response.data[data]["name"] + " - " + response.data[data]["id"];
        }

        setClients(response.data);
      } catch (error) {
        console.error(error);
        ErrorHandler(error.response, {'userToken': appContext.userToken, 'signOut': appContext.signOut});
      }
    };

    const fetchBrands = async () => {
      try {
        const response = await axios.get(Config.BASE_URL + '/admin/cars/getBrands', {
          headers: {
            Authorization: 'Bearer ' + appContext.userToken, 
            'Cache-Control': 'no-cache',
          },
        });

        setBrands(response.data);
      } catch (error) {
        console.error(error);
        ErrorHandler(error.response, {'userToken': appContext.userToken, 'signOut': appContext.signOut});
      }
    };

    fetchData();
    fetchClients();
    fetchBrands();
  }, []);

  const handleFormSubmit = async (formData) => {
    console.log("handleFormSubmit")
    console.log(formData)

    try {
      const response = await axios.post(Config.BASE_URL + '/admin/cars/edit', formData, {
        headers: {
          Authorization: 'Bearer ' + appContext.userToken, 
        }
      });

      //console.log('Response:', response);

      if (response.data.result) {
        navigate('/cars/list');
      } else {
        alert(response.data.error);
      }
    } catch (error) {
      console.error('Error:', error);
      ErrorHandler(error.response, {'userToken': appContext.userToken, 'signOut': appContext.signOut});
    }
  };

  if (data === false) {
    return (<Loading />);
  }

  if (!data) {
    return (
      <Container maxWidth="false" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 300 }}>
        <Typography variant="h4">Nema podataka za dato vozilo</Typography>
      </Container>
    )
  }

  return (
    <>
    <Container maxWidth="false">
      <Grid container spacing={1} sx={{ my: 2 }}>
        <Typography variant="h4">Izmena vozila {data.garageNumber} - {data.registrationPlate} - {data.brandName} {data.model}</Typography>
      </Grid>
      <Divider light />
      <FormErrorProvider
        onError={(error) => {
          if (error.type === 'required') {
            return 'Popunite obavezno polje'
          } else if (error.type === 'pattern') {
            return 'Unesite ispravnu e-mail adresu'
          }
          return error?.message
        }}
      >
        <FormContainer     
          defaultValues={data}
          onSuccess={formData => handleFormSubmit(formData)}>
          <Grid container spacing={1} sx={{ my: 1 }}>
            <Grid item xs={3} textAlign="left" sx={{ fontWeight: 'bold' }}>Garažni broj: <Typography variant="h6" display="inline-block" color="red">*</Typography></Grid>
            <Grid item xs={3} textAlign="left"><TextFieldElement name="garageNumber" size="small" fullWidth required /></Grid>
          </Grid>
          <Grid container spacing={1} sx={{ my: 1 }}>
            <Grid item xs={3} textAlign="left" sx={{ fontWeight: 'bold' }}>Status vozila:</Grid>
            <Grid item xs={3} textAlign="left"><TextFieldElement name="status" size="small" fullWidth /></Grid>
          </Grid>
          <Grid container spacing={1} sx={{ my: 1 }}>
            <Grid item xs={3} textAlign="left" sx={{ fontWeight: 'bold' }}>Zakupac: <Typography variant="h6" display="inline-block" color="red">*</Typography></Grid>
            <Grid item xs={3} textAlign="left">
              <AutocompleteElement
                name="clientId"
                matchId
                fullWidth
                required
                options={clients}
                textFieldProps={{
                  InputProps: { size: "small" },
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={1} sx={{ my: 1 }}>
            <Grid item xs={3} textAlign="left" sx={{ fontWeight: 'bold' }}>Broj ugovora:</Grid>
            <Grid item xs={3} textAlign="left"><TextFieldElement name="contractNumber" size="small" fullWidth /></Grid>
          </Grid>
          <Grid container spacing={1} sx={{ my: 1 }}>
            <Grid item xs={3} textAlign="left" sx={{ fontWeight: 'bold' }}>Status ugovora:</Grid>
            <Grid item xs={3} textAlign="left"><TextFieldElement name="contractStatus" size="small" fullWidth /></Grid>
          </Grid>
          <Grid container spacing={1} sx={{ my: 1 }}>
            <Grid item xs={3} textAlign="left" sx={{ fontWeight: 'bold' }}>Datum početka ugovora:</Grid>
            <Grid item xs={3} textAlign="left"><TextFieldElement name="contractStartDate" size="small" type="date" fullWidth /></Grid>
          </Grid>
          <Grid container spacing={1} sx={{ my: 1 }}>
            <Grid item xs={3} textAlign="left" sx={{ fontWeight: 'bold' }}>Datum završetka ugovora:</Grid>
            <Grid item xs={3} textAlign="left"><TextFieldElement name="contractEndDate" size="small" type="date" fullWidth /></Grid>
          </Grid>
          <Grid container spacing={1} sx={{ my: 1 }}>
            <Grid item xs={3} textAlign="left" sx={{ fontWeight: 'bold' }}>Tip vozila:</Grid>
            <Grid item xs={3} textAlign="left"><TextFieldElement name="type" size="small" fullWidth /></Grid>
          </Grid>
          <Grid container spacing={1} sx={{ my: 1 }}>
            <Grid item xs={3} textAlign="left" sx={{ fontWeight: 'bold' }}>Marka: <Typography variant="h6" display="inline-block" color="red">*</Typography></Grid>
            <Grid item xs={3} textAlign="left">
            <SelectElement
                name="brandId"
                fullWidth
                required
                size="small"
                labelKey="name"
                valueKey="brandId"
                options={brands}
                />
            </Grid>
          </Grid>
          <Grid container spacing={1} sx={{ my: 1 }}>
            <Grid item xs={3} textAlign="left" sx={{ fontWeight: 'bold' }}>Model: <Typography variant="h6" display="inline-block" color="red">*</Typography></Grid>
            <Grid item xs={3} textAlign="left"><TextFieldElement name="model" size="small" fullWidth required /></Grid>
          </Grid>
          <Grid container spacing={1} sx={{ my: 1 }}>
            <Grid item xs={3} textAlign="left" sx={{ fontWeight: 'bold' }}>Godina proizvodnje:</Grid>
            <Grid item xs={3} textAlign="left"><TextFieldElement name="manufactureYear" size="small" fullWidth /></Grid>
          </Grid>
          <Grid container spacing={1} sx={{ my: 1 }}>
            <Grid item xs={3} textAlign="left" sx={{ fontWeight: 'bold' }}>Registarska oznaka:</Grid>
            <Grid item xs={3} textAlign="left"><TextFieldElement name="registrationPlate" size="small" fullWidth /></Grid>
          </Grid>
          <Grid container spacing={1} sx={{ my: 1 }}>
            <Grid item xs={3} textAlign="left" sx={{ fontWeight: 'bold' }}>Broj saobraćajne dozvole:</Grid>
            <Grid item xs={3} textAlign="left"><TextFieldElement name="trafficLicenseNumber" size="small" fullWidth /></Grid>
          </Grid>
          <Grid container spacing={1} sx={{ my: 1 }}>
            <Grid item xs={3} textAlign="left" sx={{ fontWeight: 'bold' }}>Izdata od:</Grid>
            <Grid item xs={3} textAlign="left"><TextFieldElement name="issuedBy" size="small" fullWidth /></Grid>
          </Grid>
          <Grid container spacing={1} sx={{ my: 1 }}>
            <Grid item xs={3} textAlign="left" sx={{ fontWeight: 'bold' }}>Broj šasije:</Grid>
            <Grid item xs={3} textAlign="left"><TextFieldElement name="chassisNumber" size="small" fullWidth /></Grid>
          </Grid>
          <Grid container spacing={1} sx={{ my: 1 }}>
            <Grid item xs={3} textAlign="left" sx={{ fontWeight: 'bold' }}>Broj motora:</Grid>
            <Grid item xs={3} textAlign="left"><TextFieldElement name="engineNumber" size="small" fullWidth /></Grid>
          </Grid>
          <Grid container spacing={1} sx={{ my: 1 }}>
            <Grid item xs={3} textAlign="left" sx={{ fontWeight: 'bold' }}>Radna zapremina:</Grid>
            <Grid item xs={3} textAlign="left"><TextFieldElement name="engineDisplacement" size="small" fullWidth /></Grid>
          </Grid>
          <Grid container spacing={1} sx={{ my: 1 }}>
            <Grid item xs={3} textAlign="left" sx={{ fontWeight: 'bold' }}>Snaga motora u KW:</Grid>
            <Grid item xs={3} textAlign="left"><TextFieldElement name="enginePower" size="small" fullWidth /></Grid>
          </Grid>
          <Grid container spacing={1} sx={{ my: 1 }}>
            <Grid item xs={3} textAlign="left" sx={{ fontWeight: 'bold' }}>Broj mesta za sedenje:</Grid>
            <Grid item xs={3} textAlign="left"><TextFieldElement name="seatsNumber" size="small" fullWidth /></Grid>
          </Grid>
          <Grid container spacing={1} sx={{ my: 1 }}>
            <Grid item xs={3} textAlign="left" sx={{ fontWeight: 'bold' }}>Boja:</Grid>
            <Grid item xs={3} textAlign="left"><TextFieldElement name="color" size="small" fullWidth /></Grid>
          </Grid>
          <Grid container spacing={1} sx={{ my: 1 }}>
            <Grid item xs={3} textAlign="left" sx={{ fontWeight: 'bold' }}>Masa praznog vozila:</Grid>
            <Grid item xs={3} textAlign="left"><TextFieldElement name="weight" size="small" fullWidth /></Grid>
          </Grid>
          <Grid container spacing={1} sx={{ my: 1 }}>
            <Grid item xs={3} textAlign="left" sx={{ fontWeight: 'bold' }}>Dozvoljena masa vozila:</Grid>
            <Grid item xs={3} textAlign="left"><TextFieldElement name="maximumLoad" size="small" fullWidth /></Grid>
          </Grid>
          <Grid container spacing={1} sx={{ my: 1 }}>
            <Grid item xs={3} textAlign="left" sx={{ fontWeight: 'bold' }}>Trenutna kilometraža:</Grid>
            <Grid item xs={3} textAlign="left"><TextFieldElement name="currentMileage" size="small" fullWidth /></Grid>
          </Grid>
          <Grid container spacing={1} sx={{ my: 1 }}>
            <Grid item xs={3} textAlign="left" sx={{ fontWeight: 'bold' }}>Paket:</Grid>
            <Grid item xs={3} textAlign="left">
              <Box><CheckboxElement name="packageOfServiceKasko" size="small" label="Kasko osiguranje" /></Box>
              <Box><CheckboxElement name="packageOfServicePickup" size="small" label="Pick up - preuzimanje vozila" /></Box>
              <Box><CheckboxElement name="packageOfServiceService" size="small" label="Servis vozila" /></Box>
              <Box><CheckboxElement name="packageOfServiceTires" size="small" label="Zamena pneumatika" /></Box>
            </Grid>
          </Grid>
          <Grid container spacing={1} sx={{ my: 1 }}>
            <Grid item xs={3} textAlign="left" sx={{ fontWeight: 'bold' }}>Dodatna oprema:</Grid>
            <Grid item xs={3} textAlign="left"><TextFieldElement name="additionalEquipment" size="small" fullWidth /></Grid>
          </Grid>
          <Grid container spacing={1} sx={{ my: 1 }}>
            <Grid item xs={3} textAlign="left" sx={{ fontWeight: 'bold' }}>Napomena 1:</Grid>
            <Grid item xs={3} textAlign="left"><TextFieldElement name="note1" size="small" fullWidth /></Grid>
          </Grid>
          <Grid container spacing={1} sx={{ my: 1 }}>
            <Grid item xs={3} textAlign="left" sx={{ fontWeight: 'bold' }}>Napomena 2:</Grid>
            <Grid item xs={3} textAlign="left"><TextFieldElement name="note2" size="small" fullWidth /></Grid>
          </Grid>
          <Grid container spacing={1} sx={{ my: 1 }}>
            <Grid item xs={3} textAlign="left" sx={{ fontWeight: 'bold' }}>Napomena 3:</Grid>
            <Grid item xs={3} textAlign="left"><TextFieldElement name="note3" size="small" fullWidth /></Grid>
          </Grid>
          <Grid container spacing={1} sx={{ my: 1 }}>
            <Grid item xs={3} textAlign="left" sx={{ fontWeight: 'bold' }}>Napomena 4:</Grid>
            <Grid item xs={3} textAlign="left"><TextFieldElement name="note4" size="small" fullWidth /></Grid>
          </Grid>
          <Grid container spacing={1} sx={{ my: 1 }}>
            <Grid item xs={3} textAlign="left" sx={{ fontWeight: 'bold' }}>Napomena 5:</Grid>
            <Grid item xs={3} textAlign="left"><TextFieldElement name="note5" size="small" fullWidth /></Grid>
          </Grid>
          <Grid container spacing={1} sx={{ my: 1 }}>
            <Grid item xs={3} textAlign="left" sx={{ fontWeight: 'bold' }}>Prekoračenje kilometraže:</Grid>
            <Grid item xs={3} textAlign="left"><TextFieldElement name="mileageOverrun" size="small" fullWidth /></Grid>
          </Grid>
          <Grid container spacing={1} sx={{ my: 1 }}>
            <Grid item xs={3} textAlign="left" sx={{ fontWeight: 'bold' }}>Ugovorena kilometraža period:</Grid>
            <Grid item xs={3} textAlign="left"><TextFieldElement name="allowedMileagePeriod" size="small" fullWidth /></Grid>
          </Grid>
          <Grid container spacing={1} sx={{ my: 1 }}>
            <Grid item xs={3} textAlign="left" sx={{ fontWeight: 'bold' }}>Ugovorena mesečna kilometraža:</Grid>
            <Grid item xs={3} textAlign="left"><TextFieldElement name="allowedMileageMonth" size="small" fullWidth /></Grid>
          </Grid>
          <Grid container spacing={1} sx={{ my: 1 }}>
            <Grid item xs={3} textAlign="left" sx={{ fontWeight: 'bold' }}>Ugovorena godišnja kilometraža:</Grid>
            <Grid item xs={3} textAlign="left"><TextFieldElement name="allowedMileageYear" size="small" fullWidth /></Grid>
          </Grid>
          <Grid container spacing={1} sx={{ my: 1 }}>
            <Grid item xs={3} textAlign="left" sx={{ fontWeight: 'bold' }}>Trajanje zakupa:</Grid>
            <Grid item xs={3} textAlign="left"><TextFieldElement name="leaseDuration" type='date' size="small" fullWidth /></Grid>
          </Grid>
          <Grid container spacing={1} sx={{ my: 1 }}>
            <Grid item xs={3} textAlign="left" sx={{ fontWeight: 'bold' }}>Datum prve registracije:</Grid>
            <Grid item xs={3} textAlign="left"><TextFieldElement name="firstRegistrationDate" type="date" size="small" fullWidth /></Grid>
          </Grid>
          <Grid container spacing={1} sx={{ my: 1 }}>
            <Grid item xs={3} textAlign="left" sx={{ fontWeight: 'bold' }}>Vrsta goriva:</Grid>
            <Grid item xs={3} textAlign="left"><TextFieldElement name="fuelType" size="small" fullWidth /></Grid>
          </Grid>
          <Grid container spacing={1} sx={{ my: 1 }}>
            <Grid item xs={3} textAlign="left" sx={{ fontWeight: 'bold' }}>Pomoć na putu u zemlji:</Grid>
            <Grid item xs={3} textAlign="left"><TextFieldElement name="helpDomestic" size="small" fullWidth /></Grid>
          </Grid>
          <Grid container spacing={1} sx={{ my: 1 }}>
            <Grid item xs={3} textAlign="left" sx={{ fontWeight: 'bold' }}>Pomoć na putu u inostranstvu:</Grid>
            <Grid item xs={3} textAlign="left"><TextFieldElement name="helpForeign" size="small" fullWidth /></Grid>
          </Grid>
          <Grid container spacing={1} sx={{ my: 1 }}>
            <Grid item xs={3} textAlign="left" sx={{ fontWeight: 'bold' }}>Vlasnik:</Grid>
            <Grid item xs={3} textAlign="left"><TextFieldElement name="owner" size="small" fullWidth /></Grid>
          </Grid>
          <Grid container spacing={1} sx={{ my: 1 }}>
            <Grid item xs={3} textAlign="left" sx={{ fontWeight: 'bold' }}>GPS:</Grid>
            <Grid item xs={3} textAlign="left">
            <SelectElement
              name="gps"
              fullWidth
              size="small"
              options={[
                {
                  id: true,
                  label: 'DA',
                  
                },
                {
                  id: false,
                  label: 'NE',
                },
              ]}
            />
            </Grid>
          </Grid>
          <Grid container spacing={1} sx={{ my: 1 }}>
            <Grid item xs={12} textAlign="center" >
            <Button type="submit" color="success" variant="contained">Izmeni</Button>
            <Button variant="contained" color="primary" sx={{mx: 1}} onClick={() => navigate('/cars/list')}>Nazad</Button>
            </Grid>
          </Grid>
      </FormContainer>
      </FormErrorProvider>
    </Container>
    </>
  );
}