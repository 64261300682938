const Messages = {
    ERR_INVALID_TOKEN: 'Nepoznat token.',
    ERR_BAD_DOMAIN: 'E-mail adresa nije validna. Molimo unesite e-mail adresu sa domenom kompanije iz OMR Grupe.',
    ERR_DUPLICATE_EMAIL: 'Nalog je već registrovan. Proverite e-mail sanduče za aktivacioni link.',
    ERR_DUPLICATE_EMAIL_ACTIVATED: 'Nalog je već registrovan i aktiviran.',
    ERR_USER_NOT_ACTIVATED: 'Nalog nije aktiviran. Proverite e-mail sanduče za aktivacioni link.',
    ERR_INVALID_PASSWORD: 'Pogrešna lozinka. Pokušajte ponovo ili idite na "Zaboravljena lozinka".',
    ERR_USER_NOT_FOUND: 'Nalog nije pronađen. Registrujte se pritiskom na "Registruj se".',
    TXT_FILL_REQUIRED_FIELDS: 'Popunite obavezna polja.',
    TXT_PASSWORD_FIELDS_NOT_MATCH: 'Unesite istu lozinku u oba naznačena polja.',
    TXT_SIGNUP_DONE: 'Registracija uspešna. Proverite e-mail koji ste naveli pri registraciji za aktivacioni link.',
    TXT_FORGOTTEN_PASSWORD_FORM: 'Unesite e-mail adresu naloga koji ste registrovali i aktivirali.',
    TXT_FORGOTTEN_PASSWORD_SUBMIT: 'Zahtev za izmenu lozinke je prosleđen. Nakon uspešne validacije, na datu e-mail adresu će biti poslat link ka formi za izmenu lozinke.',
    TXT_NEW_LEAD_SUBMIT: 'Uspešno ste dodali preporuku. Biće Vam dodeljeni poeni nakon što nadležna osoba proveri podatke.',
    TXT_INVALID_EMAIL: 'Unesite ispravnu e-mail adresu.',
    Unauthorized: 'Nemate dozvolu za pristup traženoj stranici',
}

export default Messages;