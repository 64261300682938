import React, { useState, useEffect } from 'react';
import { useSearchParams, useNavigate, useLocation } from "react-router-dom";
import { Icon, TextField, Typography, Divider, Button, Grid, Container, Table, TableBody, TableCell, TableContainer, TableRow, Input, IconButton, InputAdornment } from '@mui/material';
import {FormErrorProvider, FormContainer, TextFieldElement, SelectElement, AutocompleteElement} from 'react-hook-form-mui'
import axios from 'axios';
  
import AppContext from '../../components/AppContext';
import ErrorHandler from '../../components/ErrorHandler';
import Loading from '../../components/Loading';

import Config from '../../utils/Config';

export default function Edit() {
  const navigate = useNavigate();
  const [queryParameters] = useSearchParams();
  const [data, setData] = useState(false);
  const [cars, setCars] = useState([]);
  const [insuranceCompanies, setInsuranceCompanies] = useState([]);
  const [users, setUsers] = useState([]);

  const [uploadObjectField, setUploadObjectField] = useState(null);
  const [uploadedImage, setUploadedImage] = useState(null);

  const { appContext } = React.useContext(AppContext);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(Config.BASE_URL + '/admin/registrations/get?id=' + queryParameters.get('id'), {
          headers: {
            Authorization: 'Bearer ' + appContext.userToken, 
            'Cache-Control': 'no-cache',
          },
        });

        // prepare for form inputs
        response.data.carId = response.data.car.carId;
        response.data.insuranceCompanyId = response.data.insuranceCompany?.insuranceCompanyId;
        response.data.userId = response.data.user?.userId;
        
        // remove not needed data
        delete response.data.car;
        delete response.data.insuranceCompany;
        delete response.data.user;

        setData(response.data);
      } catch (error) {
        console.error(error);
        ErrorHandler(error, {'userToken': appContext.userToken, 'signOut': appContext.signOut});
      }
    };

    const fetchCars = async () => {
      try {
        const response = await axios.get(Config.BASE_URL + '/admin/cars/list', {
          headers: {
            Authorization: 'Bearer ' + appContext.userToken, 
            'Cache-Control': 'no-cache',
          },
        });

        for (const dataId in response.data) {
          response.data[dataId]["label"] = response.data[dataId]["garageNumber"] + " - " + response.data[dataId]["registrationPlate"];
        }

        setCars(response.data);
      } catch (error) {
        console.error(error);
        ErrorHandler(error.response, {'userToken': appContext.userToken, 'signOut': appContext.signOut});
      }
    };

    const fetchInsurances = async () => {
      try {
        const response = await axios.get(Config.BASE_URL + '/admin/insurances/companies/list', {
          headers: {
            Authorization: 'Bearer ' + appContext.userToken, 
            'Cache-Control': 'no-cache',
          },
        });

        setInsuranceCompanies(response.data);
      } catch (error) {
        console.error(error);
        ErrorHandler(error.response, {'userToken': appContext.userToken, 'signOut': appContext.signOut});
      }
    };

    const fetchUsers = async () => {
      try {
        const response = await axios.get(Config.BASE_URL + '/admin/users/list', {
          headers: {
            Authorization: 'Bearer ' + appContext.userToken, 
            'Cache-Control': 'no-cache',
          },
        });

        for (const dataId in response.data) {
          response.data[dataId]["label"] = response.data[dataId]["phone"] + " - " + response.data[dataId]["name"];
        }

        setUsers(response.data);
      } catch (error) {
        console.error(error);
        ErrorHandler(error.response, {'userToken': appContext.userToken, 'signOut': appContext.signOut});
      }
    };

    fetchData();
    fetchCars();
    fetchInsurances();
    fetchUsers();
  }, []);

  const handleFormSubmit = async (formData) => {
    formData.imageLicence = data.imageLicence;
    formData.imageLicenceBack = data.imageLicenceBack;
    formData.imageIdCard = data.imageIdCard;
    formData.imageIdCardBack = data.imageIdCardBack;
    formData.imageDashboard = data.imageDashboard;
    formData.imageCarLeftTop = data.imageCarLeftTop;
    formData.imageCarLeftBottom = data.imageCarLeftBottom;
    formData.imageCarRightTop = data.imageCarRightTop;
    formData.imageCarRightBottom = data.imageCarRightBottom;
    formData.imageCarBackLeftTop = data.imageCarBackLeftTop;
    formData.imageCarBackLeftBottom = data.imageCarBackLeftBottom;
    formData.imageCarBackRightTop = data.imageCarBackRightTop;
    formData.imageCarBackRightBottom = data.imageCarBackRightBottom;
    
    console.log("handleFormSubmit")
    console.log(formData)

    try {
      const response = await axios.post(Config.BASE_URL + '/admin/registrations/edit', formData, {
        headers: {
          Authorization: 'Bearer ' + appContext.userToken, 
        }
      });

      //console.log('Response:', response);

      navigate('/registrations/list');
    } catch (error) {
      console.error('Error:', error);
      ErrorHandler(error.response, {'userToken': appContext.userToken, 'signOut': appContext.signOut});
    }
  };

  const uploadFile = (e) => {
    const reader = new FileReader()

    reader.onabort = () => console.log('file reading was aborted')
    reader.onerror = () => console.log('file reading has failed')
    reader.onload = (e) => {
      setUploadedImage(reader.result)
    }
    reader.readAsDataURL(e.target.files?.item(0))
  };

  if (data === false) {
    return (<Loading />);
  }

  if (!data) {
    return (
      <Container maxWidth="false" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 300 }}>
        <Typography variant="h4">Nema podataka</Typography>
      </Container>
    )
  }

  // set uploaded image to data object
  if (uploadObjectField && uploadedImage) {
    setData({...data, [uploadObjectField]: uploadedImage});
    setUploadObjectField(null);
    setUploadedImage(null);
  }

  return (
    <>
    <Container maxWidth="false">
      <Grid container spacing={1} sx={{ my: 2 }}>
        <Typography variant="h4">Izmena registracije {data.title}</Typography>
      </Grid>
      <Divider light />
      <FormErrorProvider
        onError={(error) => {
          if (error.type === 'required') {
            return 'Popunite obavezno polje'
          } else if (error.type === 'pattern') {
            return 'Unesite ispravnu e-mail adresu'
          }
          return error?.message
        }}
      >
        <FormContainer     
          defaultValues={data}
          onSuccess={formData => handleFormSubmit(formData)}>
          <Grid container spacing={1} sx={{ my: 1 }}>
            <Grid item xs={3} textAlign="left" sx={{ fontWeight: 'bold' }}>Naziv: <Typography variant="h6" display="inline-block" color="red">*</Typography></Grid>
            <Grid item xs={3} textAlign="left"><TextFieldElement name="title" size="small" fullWidth required /></Grid>
          </Grid>
          <Grid container spacing={1} sx={{ my: 1 }}>
            <Grid item xs={3} textAlign="left" sx={{ fontWeight: 'bold' }}>Vozilo: <Typography variant="h6" display="inline-block" color="red">*</Typography></Grid>
            <Grid item xs={3} textAlign="left">
              <AutocompleteElement
                name="carId"
                matchId
                fullWidth
                required
                options={cars}
                textFieldProps={{
                  InputProps: { size: "small" },
                }}
                />
            </Grid>
          </Grid>
          <Grid container spacing={1} sx={{ my: 1 }}>
            <Grid item xs={3} textAlign="left" sx={{ fontWeight: 'bold' }}>AO osiguranje: <Typography variant="h6" display="inline-block" color="red">*</Typography></Grid>
            <Grid item xs={3} textAlign="left">
              <SelectElement
                name="insuranceCompanyId"
                fullWidth
                required
                size="small"
                labelKey="name"
                valueKey="insuranceCompanyId"
                options={insuranceCompanies}
                />
            </Grid>
          </Grid>
          <Grid container spacing={1} sx={{ my: 1 }}>
            <Grid item xs={3} textAlign="left" sx={{ fontWeight: 'bold' }}>Broj polise:</Grid>
            <Grid item xs={3} textAlign="left"><TextFieldElement name="policyNumber" size="small" fullWidth /></Grid>
          </Grid>
          <Grid container spacing={1} sx={{ my: 1 }}>
            <Grid item xs={3} textAlign="left" sx={{ fontWeight: 'bold' }}>Od datuma:</Grid>
            <Grid item xs={3} textAlign="left"><TextFieldElement name="startDate" size="small" type="date" fullWidth /></Grid>
          </Grid>
          <Grid container spacing={1} sx={{ my: 1 }}>
            <Grid item xs={3} textAlign="left" sx={{ fontWeight: 'bold' }}>Do datuma:</Grid>
            <Grid item xs={3} textAlign="left"><TextFieldElement name="endDate" size="small" type="date" fullWidth /></Grid>
          </Grid>
          <Grid container spacing={1} sx={{ my: 1 }}>
            <Grid item xs={3} textAlign="left" sx={{ fontWeight: 'bold' }}>Datum sa nalepnice:</Grid>
            <Grid item xs={3} textAlign="left"><TextFieldElement name="stickerDate" size="small" type="date" fullWidth /></Grid>
          </Grid>
          <Grid container spacing={1} sx={{ my: 1 }}>
            <Grid item xs={3} textAlign="left" sx={{ fontWeight: 'bold' }}>Trenutna kilometraža:</Grid>
            <Grid item xs={3} textAlign="left"><TextFieldElement name="mileage" size="small" fullWidth /></Grid>
          </Grid>
          <Grid container spacing={1} sx={{ my: 1 }}>
            <Grid item xs={3} textAlign="left" sx={{ fontWeight: 'bold' }}>Vozačka dozvola:</Grid>
            <Grid item xs={3} textAlign="left">
              <TableContainer>
                <Table>
                  <TableBody>
                  <TableRow>
                    <TableCell style={{borderBottomWidth: 0, padding: 0}}><img src={data.imageLicence} height={data.imageLicence == null ? 0 : 100} /></TableCell>
                    <TableCell style={{borderBottomWidth: 0, padding: 0}}><img src={data.imageLicenceBack} height={data.imageLicenceBack == null ? 0 : 100} /></TableCell>
                    </TableRow>
                    <TableRow>
                    <TableCell style={{borderBottomWidth: 0, padding: 0}}>
                      <TextField
                        className="uploadIcon"
                        InputProps={{
                          readOnly: true,
                          endAdornment: (
                            <>
                                <IconButton aria-label="upload" component="label">
                                  <Icon>publish</Icon>
                                  <input hidden type="file" onChange={(e) => {setUploadObjectField("imageLicence"); uploadFile(e);}} />
                                </IconButton>
                            </>
                          ),
                        }}
                      />        
                      <IconButton aria-label="delete" component="label" style={{padding: 0, display: data.imageLicence == null ? 'none' : 'inline-block' }} onClick={() => setData({...data, imageLicence: null}) }>
                        <Icon>delete</Icon>
                      </IconButton>           
                    </TableCell>
                    <TableCell style={{borderBottomWidth: 0, padding: 0}}>
                      <TextField
                        className="uploadIcon"
                        InputProps={{
                          readOnly: true,
                          endAdornment: (
                            <>
                                <IconButton aria-label="upload" component="label">
                                  <Icon>publish</Icon>
                                  <input hidden type="file" onChange={(e) => {setUploadObjectField("imageLicenceBack"); uploadFile(e);}} />
                                </IconButton>
                            </>
                          ),
                        }}
                      />        
                      <IconButton aria-label="delete" component="label" style={{padding: 0, display: data.imageLicenceBack == null ? 'none' : 'inline-block' }} onClick={() => setData({...data, imageLicenceBack: null}) }>
                        <Icon>delete</Icon>
                      </IconButton>           
                    </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
          <Grid container spacing={1} sx={{ my: 1 }}>
            <Grid item xs={3} textAlign="left" sx={{ fontWeight: 'bold' }}>Lična karta:</Grid>
            <Grid item xs={3} textAlign="left">
              <TableContainer>
                <Table>
                  <TableBody>
                  <TableRow>
                    <TableCell style={{borderBottomWidth: 0, padding: 0}}><img src={data.imageIdCard} height={data.imageIdCard == null ? 0 : 100} /></TableCell>
                    <TableCell style={{borderBottomWidth: 0, padding: 0}}><img src={data.imageIdCardBack} height={data.imageIdCardBack == null ? 0 : 100} /></TableCell>
                    </TableRow>
                    <TableRow>
                    <TableCell style={{borderBottomWidth: 0, padding: 0}}>
                      <TextField
                        className="uploadIcon"
                        InputProps={{
                          readOnly: true,
                          endAdornment: (
                            <>
                                <IconButton aria-label="upload" component="label">
                                  <Icon>publish</Icon>
                                  <input hidden type="file" onChange={(e) => {setUploadObjectField("imageIdCard"); uploadFile(e);}} />
                                </IconButton>
                            </>
                          ),
                        }}
                      />        
                      <IconButton aria-label="delete" component="label" style={{padding: 0, display: data.imageIdCard == null ? 'none' : 'inline-block' }} onClick={() => setData({...data, imageIdCard: null}) }>
                        <Icon>delete</Icon>
                      </IconButton>           
                    </TableCell>
                    <TableCell style={{borderBottomWidth: 0, padding: 0}}>
                      <TextField
                        className="uploadIcon"
                        InputProps={{
                          readOnly: true,
                          endAdornment: (
                            <>
                                <IconButton aria-label="upload" component="label">
                                  <Icon>publish</Icon>
                                  <input hidden type="file" onChange={(e) => {setUploadObjectField("imageIdCardBack"); uploadFile(e);}} />
                                </IconButton>
                            </>
                          ),
                        }}
                      />        
                      <IconButton aria-label="delete" component="label" style={{padding: 0, display: data.imageIdCardBack == null ? 'none' : 'inline-block' }} onClick={() => setData({...data, imageIdCardBack: null}) }>
                        <Icon>delete</Icon>
                      </IconButton>           
                    </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
          <Grid container spacing={1} sx={{ my: 1 }}>
            <Grid item xs={3} textAlign="left" sx={{ fontWeight: 'bold' }}>Fotografija kilometar sata:</Grid>
            <Grid item xs={3} textAlign="left">
              <TableContainer>
                <Table>
                  <TableBody>
                  <TableRow>
                    <TableCell style={{borderBottomWidth: 0, padding: 0}}><img src={data.imageDashboard} height={data.imageDashboard == null ? 0 : 100} /></TableCell>
                    </TableRow>
                    <TableRow>
                    <TableCell style={{borderBottomWidth: 0, padding: 0}}>
                      <TextField
                        className="uploadIcon"
                        InputProps={{
                          readOnly: true,
                          endAdornment: (
                            <>
                                <IconButton aria-label="upload" component="label">
                                  <Icon>publish</Icon>
                                  <input hidden type="file" onChange={(e) => {setUploadObjectField("imageDashboard"); uploadFile(e);}} />
                                </IconButton>
                            </>
                          ),
                        }}
                      />        
                      <IconButton aria-label="delete" component="label" style={{padding: 0, display: data.imageDashboard == null ? 'none' : 'inline-block' }} onClick={() => setData({...data, imageDashboard: null}) }>
                        <Icon>delete</Icon>
                      </IconButton>           
                    </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
          <Grid container spacing={1} sx={{ my: 1 }}>
            <Grid item xs={3} textAlign="left" sx={{ fontWeight: 'bold' }}>Fotografije vozila:</Grid>
            <Grid item xs={3} textAlign="left">
              <TableContainer>
                <Table>
                  <TableBody>
                  <TableRow>
                    <TableCell style={{borderBottomWidth: 0, padding: 0}}><img src={data.imageCarLeftTop} height={data.imageCarLeftTop == null ? 0 : 100} /></TableCell>
                    <TableCell style={{borderBottomWidth: 0, padding: 0}}><img src={data.imageCarLeftBottom} height={data.imageCarLeftBottom == null ? 0 : 100} /></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{borderBottomWidth: 0, padding: 0}}>
                      <TextField
                        className="uploadIcon"
                        InputProps={{
                          readOnly: true,
                          endAdornment: (
                            <>
                                <IconButton aria-label="upload" component="label">
                                  <Icon>publish</Icon>
                                  <input hidden type="file" onChange={(e) => {setUploadObjectField("imageCarLeftTop"); uploadFile(e);}} />
                                </IconButton>
                            </>
                          ),
                        }}
                      />        
                      <IconButton aria-label="delete" component="label" style={{padding: 0, display: data.imageCarLeftTop == null ? 'none' : 'inline-block' }} onClick={() => setData({...data, imageCarLeftTop: null}) }>
                        <Icon>delete</Icon>
                      </IconButton>           
                    </TableCell>
                    <TableCell style={{borderBottomWidth: 0, padding: 0}}>
                      <TextField
                        className="uploadIcon"
                        InputProps={{
                          readOnly: true,
                          endAdornment: (
                            <>
                                <IconButton aria-label="upload" component="label">
                                  <Icon>publish</Icon>
                                  <input hidden type="file" onChange={(e) => {setUploadObjectField("imageCarLeftBottom"); uploadFile(e);}} />
                                </IconButton>
                            </>
                          ),
                        }}
                      />        
                      <IconButton aria-label="delete" component="label" style={{padding: 0, display: data.imageCarLeftBottom == null ? 'none' : 'inline-block' }} onClick={() => setData({...data, imageCarLeftBottom: null}) }>
                        <Icon>delete</Icon>
                      </IconButton>           
                    </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={{borderBottomWidth: 0, padding: 0}}><img src={data.imageCarBackLeftTop} height={data.imageCarBackLeftTop == null ? 0 : 100} /></TableCell>
                      <TableCell style={{borderBottomWidth: 0, padding: 0}}><img src={data.imageCarBackLeftBottom} height={data.imageCarBackLeftBottom == null ? 0 : 100} /></TableCell>
                    </TableRow>
                    <TableRow>
                    <TableCell style={{borderBottomWidth: 0, padding: 0}}>
                      <TextField
                        className="uploadIcon"
                        InputProps={{
                          readOnly: true,
                          endAdornment: (
                            <>
                                <IconButton aria-label="upload" component="label">
                                  <Icon>publish</Icon>
                                  <input hidden type="file" onChange={(e) => {setUploadObjectField("imageCarBackLeftTop"); uploadFile(e);}} />
                                </IconButton>
                            </>
                          ),
                        }}
                      />        
                      <IconButton aria-label="delete" component="label" style={{padding: 0, display: data.imageCarBackLeftTop == null ? 'none' : 'inline-block' }} onClick={() => setData({...data, imageCarBackLeftTop: null}) }>
                        <Icon>delete</Icon>
                      </IconButton>           
                    </TableCell>
                    <TableCell style={{borderBottomWidth: 0, padding: 0}}>
                      <TextField
                        className="uploadIcon"
                        InputProps={{
                          readOnly: true,
                          endAdornment: (
                            <>
                                <IconButton aria-label="upload" component="label">
                                  <Icon>publish</Icon>
                                  <input hidden type="file" onChange={(e) => {setUploadObjectField("imageCarBackLeftBottom"); uploadFile(e);}} />
                                </IconButton>
                            </>
                          ),
                        }}
                      />        
                      <IconButton aria-label="delete" component="label" style={{padding: 0, display: data.imageCarBackLeftBottom == null ? 'none' : 'inline-block' }} onClick={() => setData({...data, imageCarBackLeftBottom: null}) }>
                        <Icon>delete</Icon>
                      </IconButton>           
                    </TableCell>
                    </TableRow>
                    <TableRow>
                    <TableCell style={{borderBottomWidth: 0, padding: 0}}><img src={data.imageCarBackRightTop} height={data.imageCarBackRightTop == null ? 0 : 100} /></TableCell>
                    <TableCell style={{borderBottomWidth: 0, padding: 0}}><img src={data.imageCarBackRightBottom} height={data.imageCarBackRightBottom == null ? 0 : 100} /></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{borderBottomWidth: 0, padding: 0}}>
                      <TextField
                        className="uploadIcon"
                        InputProps={{
                          readOnly: true,
                          endAdornment: (
                            <>
                                <IconButton aria-label="upload" component="label">
                                  <Icon>publish</Icon>
                                  <input hidden type="file" onChange={(e) => {setUploadObjectField("imageCarBackRightTop"); uploadFile(e);}} />
                                </IconButton>
                            </>
                          ),
                        }}
                      />        
                      <IconButton aria-label="delete" component="label" style={{padding: 0, display: data.imageCarBackRightTop == null ? 'none' : 'inline-block' }} onClick={() => setData({...data, imageCarBackRightTop: null}) }>
                        <Icon>delete</Icon>
                      </IconButton>           
                    </TableCell>
                    <TableCell style={{borderBottomWidth: 0, padding: 0}}>
                      <TextField
                        className="uploadIcon"
                        InputProps={{
                          readOnly: true,
                          endAdornment: (
                            <>
                                <IconButton aria-label="upload" component="label">
                                  <Icon>publish</Icon>
                                  <input hidden type="file" onChange={(e) => {setUploadObjectField("imageCarBackRightBottom"); uploadFile(e);}} />
                                </IconButton>
                            </>
                          ),
                        }}
                      />        
                      <IconButton aria-label="delete" component="label" style={{padding: 0, display: data.imageCarBackRightBottom == null ? 'none' : 'inline-block' }} onClick={() => setData({...data, imageCarBackRightBottom: null}) }>
                        <Icon>delete</Icon>
                      </IconButton>           
                    </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={{borderBottomWidth: 0, padding: 0}}><img src={data.imageCarRightTop} height={data.imageCarRightTop == null ? 0 : 100} /></TableCell>
                      <TableCell style={{borderBottomWidth: 0, padding: 0}}><img src={data.imageCarRightBottom} height={data.imageCarRightBottom == null ? 0 : 100} /></TableCell>
                    </TableRow>
                    <TableRow>
                    <TableCell style={{borderBottomWidth: 0, padding: 0}}>
                      <TextField
                        className="uploadIcon"
                        InputProps={{
                          readOnly: true,
                          endAdornment: (
                            <>
                                <IconButton aria-label="upload" component="label">
                                  <Icon>publish</Icon>
                                  <input hidden type="file" onChange={(e) => {setUploadObjectField("imageCarRightTop"); uploadFile(e);}} />
                                </IconButton>
                            </>
                          ),
                        }}
                      />        
                      <IconButton aria-label="delete" component="label" style={{padding: 0, display: data.imageCarRightTop == null ? 'none' : 'inline-block' }} onClick={() => setData({...data, imageCarRightTop: null}) }>
                        <Icon>delete</Icon>
                      </IconButton>           
                    </TableCell>
                    <TableCell style={{borderBottomWidth: 0, padding: 0}}>
                      <TextField
                        className="uploadIcon"
                        InputProps={{
                          readOnly: true,
                          endAdornment: (
                            <>
                                <IconButton aria-label="upload" component="label">
                                  <Icon>publish</Icon>
                                  <input hidden type="file" onChange={(e) => {setUploadObjectField("imageCarRightBottom"); uploadFile(e);}} />
                                </IconButton>
                            </>
                          ),
                        }}
                      />        
                      <IconButton aria-label="delete" component="label" style={{padding: 0, display: data.imageCarRightBottom == null ? 'none' : 'inline-block' }} onClick={() => setData({...data, imageCarRightBottom: null}) }>
                        <Icon>delete</Icon>
                      </IconButton>           
                    </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>

          <Grid container spacing={1} sx={{ my: 1 }}>
            <Grid item xs={3} textAlign="left" sx={{ fontWeight: 'bold' }}>Korisnik:</Grid>
            <Grid item xs={3} textAlign="left">
              <AutocompleteElement
                name="userId"
                matchId
                fullWidth
                options={users}
                textFieldProps={{
                  InputProps: { size: "small" },
                }}
                />
            </Grid>
          </Grid>
          <Grid container spacing={1} sx={{ my: 1 }}>
            <Grid item xs={12} textAlign="center" >
            <Button type="submit" color="success" variant="contained">Izmeni</Button>
            <Button variant="contained" color="primary" sx={{mx: 1}} onClick={() => navigate('/registrations/list')}>Nazad</Button>
            </Grid>
          </Grid>
      </FormContainer>
      </FormErrorProvider>
    </Container>
    </>
  );
}