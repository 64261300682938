import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { FormGroup, FormControlLabel, Switch, Typography, Divider, Link, Button, Grid, Container, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TablePagination, Paper, TextField } from '@mui/material';
import {FormErrorProvider, FormContainer, TextareaAutosizeElement, AutocompleteElement} from 'react-hook-form-mui'
import TablePaginationActions from '../../components/TablePaginationActions';
import axios from 'axios';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import AppContext from '../../components/AppContext';
import ErrorHandler from '../../components/ErrorHandler';
import Loading from '../../components/Loading';

import Config from '../../utils/Config';
import {TimestampToDateTime} from '../../utils/Helpers';

export default function List() {
  const navigate = useNavigate();
  
  const [data, setData] = useState(false);
  const [users, setUsers] = useState([]);
  const [sendToAllUsers, setSendToAllUsers] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [filter, setFilter] = useState({clientName: "", phone: "", userName: "", content: ""});

  const [filterField1, setFilterField1] = useState('');
  const [filterField2, setFilterField2] = useState('');
  const [filterField3, setFilterField3] = useState('');
  const [filterField4, setFilterField4] = useState('');

  const { appContext, setDialogBox } = React.useContext(AppContext);

  const [sendMessage, setSendMessage] = useState(false);

  //console.log(userToken)
  const fetchData = async () => {
    try {
      const response = await axios.get(Config.BASE_URL + '/admin/users/messages/list', {
        headers: {
          Authorization: 'Bearer ' + appContext.userToken, 
          'Cache-Control': 'no-cache',
        },
      });

      setData(response.data);
    } catch (error) {
      console.error(error);
      ErrorHandler(error.response, {'userToken': appContext.userToken, 'signOut': appContext.signOut});
    }
  };

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get(Config.BASE_URL + '/admin/users/list', {
          headers: {
            Authorization: 'Bearer ' + appContext.userToken, 
            'Cache-Control': 'no-cache',
          },
        });

        let users = [];

        for (const dataId in response.data) {
          users.push({'id': response.data[dataId]['id'], 'label': response.data[dataId]["phone"] + " - " + response.data[dataId]["name"]});
        }

        setUsers(users);
      } catch (error) {
        console.error(error);
        ErrorHandler(error.response, {'userToken': appContext.userToken, 'signOut': appContext.signOut});
      }
    };

    fetchUsers();
    fetchData();
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleSetFilterButtonClick = () => {
    setFilter({clientName: filterField1, phone: filterField2, userName: filterField3, content: filterField4});
    setPage(0);
  };

  const handleClearFilterButtonClick = () => {
    setFilterField1('');
    setFilterField2('');
    setFilterField3('');
    setFilterField4('');

    setFilter({clientName: "", phone: "", userName: "", content: ""});
  };

  const handleSendMessage = async (formData) => {
    console.log("handleSendMessage")

    formData.sendToAllUsers = sendToAllUsers;

    if (sendToAllUsers == false && typeof formData.users === 'undefined') {
      setDialogBox({show: true, type: 'message', title: null, message: "Odaberite korisnike"});
    } else {
      setSendMessage(false);

      setDialogBox({show: true, type: 'loadingBox'});

      try {
        const response = await axios.post(Config.BASE_URL + '/admin/users/messages/sendMulti', formData, {
          headers: {
            Authorization: 'Bearer ' + appContext.userToken, 
          }
        });

        //console.log('Response:', response);
      } catch (error) {
        console.error('Error:', error);
        ErrorHandler(error.response, {'userToken': appContext.userToken, 'signOut': appContext.signOut});
      }
      
      setDialogBox({show: false});

      fetchData();
    }
  };
  
  if (data === false) {
    return (<Loading />);
  }
console.log(sendToAllUsers)
  const rows = data.filter((el) => el.clientName.toLowerCase().includes(filter.clientName.toLowerCase()))
                   .filter((el) => (el.phone || '').toLowerCase().includes(filter.phone.toLowerCase()))
                   .filter((el) => (el.userName || '').toLowerCase().includes(filter.userName.toLowerCase()))
                   .filter((el) => (el.content || '').toLowerCase().includes(filter.content.toLowerCase()));

  return (
      <Container maxWidth="false">
        <div>
        <Dialog
            open={!!sendMessage} // !! converts to "truthy" or "falsely"
            onClose={() => setSendMessage(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth="xs"
            fullWidth
        >
            <FormErrorProvider
              onError={(error) => {
                if (error.type === 'required') {
                  return 'Popunite obavezno polje'
                }
                return error?.message
              }}
            >
            <FormContainer onSuccess={formData => handleSendMessage(formData)}>
            <DialogContent>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                Korisnici:
              </Grid>
              <Grid item xs={6}>
                <FormControlLabel control={<Switch checked={sendToAllUsers} onChange={(e) => setSendToAllUsers(e.target.checked)} />} label="Svi korisnici" />
              </Grid>
              </Grid>
              <Grid container spacing={1} sx={{display: sendToAllUsers ? 'none' : 'block'}}>
              <Grid item xs={12}>
                <AutocompleteElement
                    multiple
                    showCheckbox
                    name="users"
                    matchId
                    fullWidth
                    options={users}
                    textFieldProps={{
                      InputProps: { size: "small" },
                    }}    
                    />
              </Grid>
              </Grid>
              <Grid container spacing={1}>
              <Grid item xs={12}>
                  Poruka:
                <TextareaAutosizeElement name="message" rows="5" fullWidth required />
              </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setSendMessage(false)}>Zatvori</Button>
              <Button type="submit" autoFocus>Pošalji</Button>
            </DialogActions>
            </FormContainer>
            </FormErrorProvider>
        </Dialog>
        </div>
        <Grid container spacing={1} sx={{ my: 2 }}>
          <Grid item xs={10}>
            <Typography variant="h4">Poruke</Typography>
          </Grid>
          <Grid item xs={2} textAlign="center">
            <Button variant="contained" color="warning" onClick={() => setSendMessage(true)}>Pošalji poruku</Button>
          </Grid>
        </Grid>
        <Divider light />
        <Grid container spacing={1} sx={{ my: 2 }}>
          <Grid item xs={1} textAlign="center" sx={{mt: 1}}>
            Ukupno: {rows.length}
          </Grid>
          <Grid item xs={2} textAlign="center">
            <TextField id="txtNaziv" label="Zakupac" value={filterField1} onChange={(e) => setFilterField1(e.target.value)} variant="outlined" size="small" InputLabelProps={{ shrink: true }} />
          </Grid>
          <Grid item xs={2} textAlign="center">
            <TextField id="txtMatBroj" label="Telefon" value={filterField2} onChange={(e) => setFilterField2(e.target.value)} variant="outlined" size="small" InputLabelProps={{ shrink: true }} />
          </Grid>
          <Grid item xs={2} textAlign="center">
            <TextField id="txtPIB" label="Korisnik" value={filterField3} onChange={(e) => setFilterField3(e.target.value)} variant="outlined" size="small" InputLabelProps={{ shrink: true }} />
          </Grid>
          <Grid item xs={2} textAlign="center">
            <TextField id="txtJMBG" label="Sadržaj poruke" value={filterField4} onChange={(e) => setFilterField4(e.target.value)} variant="outlined" size="small" InputLabelProps={{ shrink: true }} />
          </Grid>
          <Grid item xs={3}>
            <Button variant="contained" color="primary" sx={{ mx: 1 }} onClick={handleSetFilterButtonClick}>Primeni</Button>
            <Button variant="contained" color="error" sx={{ mx: 1 }} onClick={handleClearFilterButtonClick}>Reset</Button>
          </Grid>
        </Grid>
        <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
              labelRowsPerPage="Redova po stranici"
          />
          <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead color="secondary">
              <TableRow>
                  <TableCell>Zakupac</TableCell>
                  <TableCell align="left">Telefon</TableCell>
                  <TableCell align="left">Korisnik</TableCell>
                  <TableCell align="left">Poruka</TableCell>
                  <TableCell align="left">Poslato</TableCell>
              </TableRow>
              </TableHead>
              <TableBody>
              {rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => (
                  <TableRow
                  key={row.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 }, 
                        '&:nth-of-type(even)': { backgroundColor: "#f6f6f6" },
                        '&:hover': { backgroundColor: "#fffbc7", cursor: 'pointer' } }}
                  >
                  <TableCell component="th" scope="row">{row.clientName}</TableCell>
                  <TableCell align="left">{row.phone}</TableCell>
                  <TableCell align="left"><Link onClick={(e) => { navigate('/users/view?id=' + row.userId); e.stopPropagation(); }}>{row.userName}</Link></TableCell>
                  <TableCell align="left" sx={{ minWidth: 350 }}>{row.content}</TableCell>
                  <TableCell align="left">{TimestampToDateTime(row.timestamp)}</TableCell>
                  </TableRow>
              ))}
              </TableBody>
          </Table>
          </TableContainer>
          <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
              labelRowsPerPage="Redova po stranici"
          />
      </Container>
  );
}
