import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { Typography, Divider, Button, Grid, Container, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TablePagination, Paper, TextField } from '@mui/material';
import {FormErrorProvider, FormContainer, TextareaAutosizeElement} from 'react-hook-form-mui'
import TablePaginationActions from '../../components/TablePaginationActions';
import axios from 'axios';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import AppContext from '../../components/AppContext';
import ErrorHandler from '../../components/ErrorHandler';
import Loading from '../../components/Loading';

import Config from '../../utils/Config';
import {TimestampToDateTime} from '../../utils/Helpers';

export default function List() {
  const navigate = useNavigate();

  const [data, setData] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [filter, setFilter] = useState({clientName: "", phone: "", name: "", registrationPlate: ""});
  const [messageUser, setMessageUser] = useState(false);

  const [filterField1, setFilterField1] = useState('');
  const [filterField2, setFilterField2] = useState('');
  const [filterField3, setFilterField3] = useState('');
  const [filterField4, setFilterField4] = useState('');

  const { appContext } = React.useContext(AppContext);

  //console.log(userToken)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(Config.BASE_URL + '/admin/users/list', {
          headers: {
            Authorization: 'Bearer ' + appContext.userToken, 
            'Cache-Control': 'no-cache',
          },
        });

        setData(response.data);
      } catch (error) {
        console.error(error);
        ErrorHandler(error.response, {'userToken': appContext.userToken, 'signOut': appContext.signOut});
      }
    };

    fetchData();
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleSetFilterButtonClick = () => {
    setFilter({clientName: filterField1, phone: filterField2, name: filterField3, registrationPlate: filterField4});
    setPage(0);
  };

  const handleClearFilterButtonClick = () => {
    setFilterField1('');
    setFilterField2('');
    setFilterField3('');
    setFilterField4('');

    setFilter({clientName: "", phone: "", name: "", registrationPlate: ""});
  };

  const handleSendMessage = async (formData) => {
    console.log("handleSendMessage")

    formData.userId = messageUser.id;

    console.log(formData)

    try {
      const response = await axios.post(Config.BASE_URL + '/admin/users/messages/send', formData, {
        headers: {
          Authorization: 'Bearer ' + appContext.userToken, 
        }
      });

      //console.log('Response:', response);
    } catch (error) {
      console.error('Error:', error);
      ErrorHandler(error.response, {'userToken': appContext.userToken, 'signOut': appContext.signOut});
    }
    
    setMessageUser(false);
  };
  
  console.log(appContext.userData)
  if (data === false) {
    return (<Loading />);
  }

  const rows = data.filter((el) => el.clientName.toLowerCase().includes(filter.clientName.toLowerCase()))
                   .filter((el) => (el.phone || '').toLowerCase().includes(filter.phone.toLowerCase()))
                   .filter((el) => (el.name || '').toLowerCase().includes(filter.name.toLowerCase()))
                   .filter((el) => (el.registrationPlate || '').toLowerCase().includes(filter.registrationPlate.toLowerCase()));

  return (
      <Container maxWidth="false">
        <div>
        <Dialog
            open={!!messageUser} // !! converts to "truthy" or "falsely"
            onClose={() => setMessageUser(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth="xs"
            fullWidth
        >
            <FormErrorProvider
              onError={(error) => {
                if (error.type === 'required') {
                  return 'Popunite obavezno polje'
                }
                return error?.message
              }}
            >
            <FormContainer onSuccess={formData => handleSendMessage(formData)}>
            <DialogTitle id="alert-dialog-title">
            Poruka za korisnika {messageUser.name}:
            </DialogTitle>
            <DialogContent>
              <TextareaAutosizeElement name="message" rows="5" fullWidth required />
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setMessageUser(false)}>Zatvori</Button>
              <Button type="submit" autoFocus>Pošalji</Button>
            </DialogActions>
            </FormContainer>
            </FormErrorProvider>
        </Dialog>
        </div>
        <Grid container spacing={1} sx={{ my: 2 }}>
          <Grid item xs={9}>
            <Typography variant="h4">Korisnici</Typography>
          </Grid>
          <Grid item xs={3} textAlign="center">
            <Button variant="contained" color="success" sx={{ mx: 1 }} onClick={() => navigate('/users/add')}>Dodaj korisnika</Button>
            <Button variant="contained" color="warning" sx={{ mx: 1 }} onClick={() => navigate('/users/messages')}>Poruke</Button>
          </Grid>
        </Grid>
        <Divider light />
        <Grid container spacing={1} sx={{ my: 2 }}>
          <Grid item xs={1} textAlign="center" sx={{mt: 1}}>
            Ukupno: {rows.length}
          </Grid>
          <Grid item xs={2} textAlign="center">
            <TextField label="Kompanija" value={filterField1} onChange={(e) => setFilterField1(e.target.value)} variant="outlined" size="small" InputLabelProps={{ shrink: true }} />
          </Grid>
          <Grid item xs={2} textAlign="center">
            <TextField label="Telefon" value={filterField2} onChange={(e) => setFilterField2(e.target.value)} variant="outlined" size="small" InputLabelProps={{ shrink: true }} />
          </Grid>
          <Grid item xs={2} textAlign="center">
            <TextField label="Ime" value={filterField3} onChange={(e) => setFilterField3(e.target.value)} variant="outlined" size="small" InputLabelProps={{ shrink: true }} />
          </Grid>
          <Grid item xs={2} textAlign="center">
            <TextField label="Registracija" value={filterField4} onChange={(e) => setFilterField4(e.target.value)} variant="outlined" size="small" InputLabelProps={{ shrink: true }} />
          </Grid>
          <Grid item xs={3}>
            <Button variant="contained" color="primary" sx={{ mx: 1 }} onClick={handleSetFilterButtonClick}>Primeni</Button>
            <Button variant="contained" color="error" sx={{ mx: 1 }} onClick={handleClearFilterButtonClick}>Reset</Button>
          </Grid>
        </Grid>
        <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
              labelRowsPerPage="Redova po stranici"
          />
          <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead color="secondary">
              <TableRow>
                  <TableCell>Kompanija</TableCell>
                  <TableCell align="left">Telefon</TableCell>
                  <TableCell align="left">Ime</TableCell>
                  <TableCell align="left">Email</TableCell>
                  <TableCell align="left">Registracija</TableCell>
                  <TableCell align="left">OS</TableCell>
                  <TableCell align="left">App verzija</TableCell>
                  <TableCell align="left">Upisan</TableCell>
                  <TableCell align="left">Zadnji pristup</TableCell>
                  <TableCell align="left"></TableCell>
              </TableRow>
              </TableHead>
              <TableBody>
              {rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => (
                  <TableRow
                  key={row.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 }, 
                        '&:nth-of-type(even)': { backgroundColor: "#f6f6f6" },
                        '&:hover': { backgroundColor: "#fffbc7", cursor: 'pointer' } }}
                  onClick={() => navigate('/users/view?id=' + row.id)}
                  >
                  <TableCell component="th" scope="row">{row.clientName}</TableCell>
                  <TableCell align="left">{row.phone}</TableCell>
                  <TableCell align="left">{row.name}</TableCell>
                  <TableCell align="left">{row.email}</TableCell>
                  <TableCell align="left">{row.registrationPlate}</TableCell>
                  <TableCell align="left">{row.platform?.split(',')[0]} {row.platform?.split(',')[1]}</TableCell>
                  <TableCell align="left">{row.platform?.split(',')[2]}</TableCell>
                  <TableCell align="left">{TimestampToDateTime(row.created)}</TableCell>
                  <TableCell align="left">{TimestampToDateTime(row.lastSeen)}</TableCell>
                  <TableCell align="left">
                    <Button variant="text" color="secondary" size="small" sx={{m:0, p:0}} onClick={(e) => { navigate('/users/edit?id=' + row.id); e.stopPropagation(); }}>Izmeni</Button>
                    <Button variant="outlined" color="warning" size="small" sx={{m:0, p:0}} onClick={(e) => { setMessageUser(row); e.stopPropagation(); }}>Poruka</Button>
                  </TableCell>
                  </TableRow>
              ))}
              </TableBody>
          </Table>
          </TableContainer>
          <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
              labelRowsPerPage="Redova po stranici"
          />
      </Container>
  );
}
