import React, { useState, useEffect } from 'react';
import { useSearchParams, useNavigate, useLocation } from "react-router-dom";
import { Typography, Divider, Button, Grid, Container, TextField } from '@mui/material';
import {FormErrorProvider, FormContainer, TextFieldElement, SelectElement, AutocompleteElement} from 'react-hook-form-mui'
import axios from 'axios';
  
import AppContext from '../../components/AppContext';
import ErrorHandler from '../../components/ErrorHandler';

import Config from '../../utils/Config';

export default function Add() {
  const navigate = useNavigate();
  const [queryParameters] = useSearchParams();
  const [cars, setCars] = useState([]);
  const [insuranceCompanies, setInsuranceCompanies] = useState([]);

  const { appContext } = React.useContext(AppContext);

  useEffect(() => {
    const fetchCars = async () => {
      try {
        const response = await axios.get(Config.BASE_URL + '/admin/cars/list', {
          headers: {
            Authorization: 'Bearer ' + appContext.userToken, 
            'Cache-Control': 'no-cache',
          },
        });

        for (const dataId in response.data) {
          response.data[dataId]["label"] = response.data[dataId]["garageNumber"] + " - " + response.data[dataId]["registrationPlate"];
        }

        setCars(response.data);
      } catch (error) {
        console.error(error);
        ErrorHandler(error.response, {'userToken': appContext.userToken, 'signOut': appContext.signOut});
      }
    };

    const fetchInsurances = async () => {
      try {
        const response = await axios.get(Config.BASE_URL + '/admin/insurances/companies/list', {
          headers: {
            Authorization: 'Bearer ' + appContext.userToken, 
            'Cache-Control': 'no-cache',
          },
        });

        setInsuranceCompanies(response.data);
      } catch (error) {
        console.error(error);
        ErrorHandler(error.response, {'userToken': appContext.userToken, 'signOut': appContext.signOut});
      }
    };

    fetchCars();
    fetchInsurances();
  }, []);

  const handleFormSubmit = async (formData) => {
    console.log("handleFormSubmit")
    console.log(formData)

    try {
      const response = await axios.post(Config.BASE_URL + '/admin/insurances/add', formData, {
        headers: {
          Authorization: 'Bearer ' + appContext.userToken, 
        }
      });

      //console.log('Response:', response);

      navigate('/insurances/list');
    } catch (error) {
      console.error('Error:', error);
      ErrorHandler(error.response, {'userToken': appContext.userToken, 'signOut': appContext.signOut});
    }
  };

  return (
    <>
    <Container maxWidth="false">
      <Grid container spacing={1} sx={{ my: 2 }}>
        <Typography variant="h4">Dodavanje osiguranja</Typography>
      </Grid>
      <Divider light />
      <FormErrorProvider
        onError={(error) => {
          if (error.type === 'required') {
            return 'Popunite obavezno polje'
          } else if (error.type === 'pattern') {
            return 'Unesite ispravnu e-mail adresu'
          }
          return error?.message
        }}
      >
        <FormContainer     
          defaultValues={{
            'description': 'Nema',
          }}        
          onSuccess={formData => handleFormSubmit(formData)}>
          <Grid container spacing={1} sx={{ my: 1 }}>
            <Grid item xs={3} textAlign="left" sx={{ fontWeight: 'bold' }}>Vozilo: <Typography variant="h6" display="inline-block" color="red">*</Typography></Grid>
            <Grid item xs={3} textAlign="left">
              <AutocompleteElement
                name="carId"
                matchId
                fullWidth
                required
                options={cars}
                textFieldProps={{
                  InputProps: { size: "small" },
                }}
                />
            </Grid>
          </Grid>
          <Grid container spacing={1} sx={{ my: 1 }}>
            <Grid item xs={3} textAlign="left" sx={{ fontWeight: 'bold' }}>Osiguravajuća kuća: <Typography variant="h6" display="inline-block" color="red">*</Typography></Grid>
            <Grid item xs={3} textAlign="left">
              <SelectElement
                name="insuranceCompanyId"
                fullWidth
                required
                size="small"
                labelKey="name"
                valueKey="insuranceCompanyId"
                options={insuranceCompanies}
                />
            </Grid>
          </Grid>
          <Grid container spacing={1} sx={{ my: 1 }}>
            <Grid item xs={3} textAlign="left" sx={{ fontWeight: 'bold' }}>Broj polise:</Grid>
            <Grid item xs={3} textAlign="left"><TextFieldElement name="policyNumber" size="small" fullWidth /></Grid>
          </Grid>
          <Grid container spacing={1} sx={{ my: 1 }}>
            <Grid item xs={3} textAlign="left" sx={{ fontWeight: 'bold' }}>Period ugovora od:</Grid>
            <Grid item xs={3} textAlign="left"><TextFieldElement name="contractStartDate" size="small" type="date" fullWidth /></Grid>
          </Grid>
          <Grid container spacing={1} sx={{ my: 1 }}>
            <Grid item xs={3} textAlign="left" sx={{ fontWeight: 'bold' }}>Period ugovora do:</Grid>
            <Grid item xs={3} textAlign="left"><TextFieldElement name="contractEndDate" size="small" type="date" fullWidth /></Grid>
          </Grid>
          <Grid container spacing={1} sx={{ my: 1 }}>
            <Grid item xs={3} textAlign="left" sx={{ fontWeight: 'bold' }}>Obračunski period od:</Grid>
            <Grid item xs={3} textAlign="left"><TextFieldElement name="accountingStartDate" size="small" type="date" fullWidth /></Grid>
          </Grid>
          <Grid container spacing={1} sx={{ my: 1 }}>
            <Grid item xs={3} textAlign="left" sx={{ fontWeight: 'bold' }}>Obračunski period do:</Grid>
            <Grid item xs={3} textAlign="left"><TextFieldElement name="accountingEndDate" size="small" type="date" fullWidth /></Grid>
          </Grid>
          <Grid container spacing={1} sx={{ my: 1 }}>
            <Grid item xs={3} textAlign="left" sx={{ fontWeight: 'bold' }}>Iznos polise - godišnja premija:</Grid>
            <Grid item xs={3} textAlign="left"><TextFieldElement name="policyValue" size="small" fullWidth /></Grid>
          </Grid>
          <Grid container spacing={1} sx={{ my: 1 }}>
            <Grid item xs={3} textAlign="left" sx={{ fontWeight: 'bold' }}>Broj šteta po polisi:</Grid>
            <Grid item xs={3} textAlign="left"><TextFieldElement name="damageAmount" size="small" fullWidth /></Grid>
          </Grid>
          <Grid container spacing={1} sx={{ my: 1 }}>
            <Grid item xs={3} textAlign="left" sx={{ fontWeight: 'bold' }}>Učešće u šteti u procentima:</Grid>
            <Grid item xs={3} textAlign="left"><TextFieldElement name="damageParticipation" size="small" fullWidth /></Grid>
          </Grid>
          <Grid container spacing={1} sx={{ my: 1 }}>
            <Grid item xs={3} textAlign="left" sx={{ fontWeight: 'bold' }}>Opis učešća:</Grid>
            <Grid item xs={3} textAlign="left">
            <SelectElement
              name="description"
              fullWidth
              required
              size="small"
              options={[
                {
                  id: 'Nema',
                  label: 'Nema'
                },
                {
                  id: 'Apsolutno',
                  label: 'Apsolutno'
                },
                {
                  id: 'Relativno',
                  label: 'Relativno'
                },
              ]}
            />
            </Grid>
          </Grid>
          <Grid container spacing={1} sx={{ my: 1 }}>
            <Grid item xs={3} textAlign="left" sx={{ fontWeight: 'bold' }}>Uslovi korišćenja - link:</Grid>
            <Grid item xs={3} textAlign="left"><TextFieldElement name="termsAndConditions" size="small" fullWidth /></Grid>
          </Grid>
          <Grid container spacing={1} sx={{ my: 1 }}>
            <Grid item xs={12} textAlign="center" >
            <Button type="submit" color="success" variant="contained">Dodaj</Button>
            <Button variant="contained" color="primary" sx={{mx: 1}} onClick={() => navigate('/insurances/list')}>Nazad</Button>
            </Grid>
          </Grid>
      </FormContainer>
      </FormErrorProvider>
    </Container>
    </>
  );
}